import camelcase from 'camelcase';
import Icon from 'components/atoms/Icon';
import User from 'components/atoms/User';
import './style.scss';

function TaskLog(props) {
  const { task } = props;

  return (
    <div className="TaskLog">
      <ul className="TaskLog__list">
        {
          task.getActivityLog().length ?
            task.getActivityLog().map((date) => {
              const dateKey = camelcase('helloHello');
             
              return (
                <li key={dateKey} className="TaskLog__item">
                  <h2 className="TaskLog__date"><span>{date.date}</span></h2>
                  <ul className="TaskLog__events">
                    {
                      date.events.map((event) => {
                        return (
                          <li key={event.key} id={event.key} className="TaskLog__event">
                            <div className="TaskLog__eventMeta">
                              <Icon icon={event.icon} />
                              <h3 className="TaskLog__eventLabel"><span>{event.label}</span></h3>
                            </div>
                            <div className="TaskLog__eventContent">
                              <Icon icon="arrowRight" />
                              <User user={event.user} />
                            </div>
                          </li>
                        );
                      })
                    }
                  </ul>
                </li>
              )
            })
          :
            <li className="TaskLog__item TaskLog__item--emptyMessage">This task has no history</li>
        }
      </ul>
    </div>
  );
}

export default TaskLog;