import OutsideBox from 'outside/components/OutsideBox';
import './style.scss';

function Terms() {
  return (
    <OutsideBox classBase="Terms">
      <div className="Terms">
        <h1>Cogny ACCEPTANCE AND USE TERMS AND CONDITIONS</h1>
        <p>The following terms and conditions (the "Terms") govern your acceptance and use of the Company’s delegation services and/or participation in the Company’s baking bonds through DAB-tokens (the “Services”). You hereby, through usage of the Company’s services, confirm that you understand the Terms and expressly agree to be bound by and comply with the Terms. Please review carefully and keep a copy of these Terms for your reference. If you have any questions about these Terms, please contact us prior to using our Services.</p>
        <p>Growth Hackers STHLM AB, reg. no. 556963-5211 (hereinafter referred to as the “Company”, “we”, “us” or “our”) reserves the right, subject to applicable law, to make changes to, modify, or add or remove portions of these Terms, our Privacy Policy for Protection of Personal Data, Appendix A (the “Privacy Policy”), and other incorporated terms and policies, in our sole discretion, at any time. We encourage you to check our website frequently to see the current Terms in effect and any changes that may have been made to them. All changes made to the Terms are effective immediately when we post them. No one is authorized to modify these Terms with you or otherwise enter into an agreement with you that conflicts with these Terms, except by means of written agreement signed by an authorized agent of the Company, and any other purported modifications or alterations or conflicting terms will be null and void.</p>

        <h2>Main Features of the Services</h2>
        <p>Cogny helps users understand their data.</p>

        <h2>Applicability of the Privacy Policy</h2>
        <p>You hereby acknowledge and agree that any and all information you provide to any of the Subject Persons is governed by our Privacy Policy, and you hereby consent to all actions any of the Subject Persons take with respect to your information consistent with our Privacy Policy.</p>

        <h2>No Representation or Warranty</h2>
        <p>No party - including any of the Subject Persons - makes any representation or warranty about the suitability, reliability, availability, timeliness, security and/or accuracy of the Services. To the maximum extent permitted by applicable law, the Services and all related information, software and products are provided on "as is, where is" basis without representations, warranties or conditions of any kind, including with respect to merchantability, fitness for a particular purpose, title and non-infringement.</p>

        <h2>Limitation of Liability</h2>
        <p>You hereby acknowledge and agree that, to the maximum extent permitted by applicable law, the disclaimers of liability contained herein apply to any and all damages or injury whatsoever caused by or related to risks of, use of, or inability to use, the Services under any and all causes of action whatsoever of any kind in any jurisdiction, including, without limitation, actions for breach of warranty, breach of contract or tort (including negligence).</p>

        <p>To the maximum extent permitted by applicable law, in no event shall the Subject Persons be liable for any indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use of the Services, with the delay or inability to use the Services or related software, the provision of or failure to provide the Services, or for any information, software and products obtained in connection with your acceptance and use of the Services, or otherwise arising out of your acceptance and use of the Services, whether based on contract, tort, negligence, strict liability or otherwise, even if any of the Subject Persons or any of their suppliers have been advised of the possibility of damages.</p>

        <p>Notwithstanding anything herein to the contrary, to the maximum extent permitted by applicable law, in no event shall any Subject Person’s maximum aggregate liability for any and all claims associated with or related to these Terms and/or the use of the Services be greater than SEK 50,000.</p>

        <p>Acceptance and use of the Services is unauthorized in any jurisdiction that does not give effect to all provisions of these terms, including, without limitation, this section, or that imposes any registration obligations upon the Company.</p>

        <h2>Dispute Resolution</h2>
        <p>For any and all controversies, disputes, demands, claims, or causes of action between you and the Subject Persons (including the interpretation and scope of this Section) arising out of your acceptance or use of the Services, these Terms (as well as any related or prior agreement that you may have had with any of the Subject Persons), or any other aspect of the Services shall be subject to the exclusive jurisdiction of the courts of the Kingdom of Sweden.</p>

        <p>To the maximum extent permitted by applicable law, in connection with any claims arising out of your acceptance or use of the Services, (i) you will only be permitted to pursue claims against the Subject Persons on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding, and (ii) you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.</p>

        <h2>User Disputes</h2>
        <p>You hereby acknowledge and agree that you are solely responsible for your interactions with any other user of the Services and none of the Subject Persons will have any liability or responsibility with respect thereto.</p>

        <h2>Miscellaneous</h2>
        <p>These Terms will be governed by the laws of the Kingdom of Sweden without regard to its conflict of law provisions.</p>

        <p>The failure of any of the Subject Persons to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.</p>

        <p>You may not assign these Terms without the prior written consent of the Company, but the Company may assign or transfer these Terms, in whole or in part, without restriction.</p>

        <p>The section titles in these Terms are for convenience only and have no legal or contractual effect. Notices to you may be made via either email, regular mail or through the Company’s Telegram Channel (as defined in the Privacy Policy).</p>

        <p>You agree that no joint venture, partnership, employment, or agency relationship, and in particular no partnership agreement pursuant to the Swedish Partnership and Non-registered Partnership Act (Sw. Lag om handelsbolag och enkla bolag), exists between you and any of the Subject Persons as a result of these Terms or your acceptance or use of the Services.</p>

        <p>The performance of these Terms by any of the Subject Persons is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of any Subject Person’s right to comply with governmental, court and law enforcement requests or requirements relating to your acceptance and use of the Services or information provided to or gathered by any of the Subject Persons with respect to such use.</p>

        <p>Unless otherwise specified herein, these Terms constitute the entire agreement between you and the Subject Persons with respect to your acceptance and use of the Services and they supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and the Subject Persons with respect to your acceptance and use of the Services.</p>
      </div>
    </OutsideBox>
  );
}

export default Terms;
