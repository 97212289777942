import './style.scss';
import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import Tab from 'components/atoms/Tab';
import camelcase from 'camelcase';

function Tabs(props) {
  const history = useHistory();
  let activeTabDefault = props.children[0].props.slug;
  let removePath = props.active !== false;
  const { basepath } = props;

  props.children.forEach(function(child) {
    if (!child) return;
    if (typeof props.active !== "undefined" && child.props.slug === props.active) {
      activeTabDefault = child.props.slug;
      removePath = false;
    }
  })

  const [activeTab, setActiveTab] = useState(activeTabDefault);

  /**
   * Updates active tab when you click a non-tab link pointing to a tabs
   */
  useEffect(() => {
    setActiveTab(activeTabDefault);
  }, [activeTabDefault]) //eslint-disable-line

  if (removePath) {
    return <Redirect to={basepath} />
  }

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    const pushTarget = props.children[0].props.slug === tab ? "" : camelcase(tab);
    history.push(basepath + pushTarget);
  }
  const children = props.children;

  return (
    <div className="Tabs">
      <ol className="Tabs__list">
        {children.filter(child => child).map((child) => {
          const { icon, label, slug, meta } = child.props;

          return (
            <Tab 
              activeTab={activeTab}
              key={label}
              onClick={onClickTabItem}
              icon={icon}
              label={label}
              slug={slug}
              meta={meta}
              basepath={basepath}
            />
          );
        })}
      </ol>
      <div className="Tabs__content">
        {children.filter(child => child).map((child) => {
          if (child.props.slug !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
}

export default Tabs;