import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';
import logoHalebop from './logoHalebop@2x.png';
import logoPodspace from './logoPodspace@2x.png';

function TrustedBy(props) {
  return (
    <OutsideBox classBase="TrustedBy">
      <div className="TrustedBy">
        <h2 className="TrustedBy__title">Trusted by</h2>
        <div className="TrustedBy__cards">
          <div className="TrustedBy__card">
            <img src={logoHalebop} alt="Halebop" className="TrustedBy__logo" />
          </div>
          <div className="TrustedBy__card">
            <img src={logoPodspace} alt="Podspace" className="TrustedBy__logo" />
          </div>
        </div>

      </div>
    </OutsideBox>
  );
}

export default TrustedBy;
