import './style.scss';

function SectionHeader(props) {
  return (
    <div className={"SectionHeader " + props.className }>
      { props.children }
    </div>
  );
}

SectionHeader.defaultProps = {
  className: "",
};

export default SectionHeader;