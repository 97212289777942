import './style.scss';
import Button from 'components/atoms/Button';
import CognyAPI from 'components/_classes/CognyAPI';
import SubmitStatus from 'components/molecules/SubmitStatus';
import { useRouteMatch } from 'react-router-dom';
import React, { useState } from 'react';
import Form from 'components/atoms/Form';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="mmm-get-started">Get started with LTV and attribution</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function LTVForm(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [cookies] = useCookies(["token", "project"]);
  const [message, setMessage] = useState("");
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoaded(false);

    const newFeature = {
      feature: "ltv",
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      ReactGA.event({
        category: "UserActions",
        action: "AddFeatureLTV",
        label: cookies.token,
      });
      api.addWarehouseFeature(warehouseId, newFeature)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setMessage("You have been added to the LTV and attribution waitlist, we'll be in touch!");
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
  }

  return (
    <>
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">LTV & Attribution <span className="TaskList__logo">(beta)</span></h1>
        </div>

        <img src="/images/ltv.png" width="300" align="right" style={{ padding: 20 + 'px' }} alt="Life Time Value" />
        <p>Modelling expected Life Time Value (LTV) for your customers at an early stage is key to quickly evaulating your marketing and sales efforts.</p>
        <p>With attribution, you can start to figure out how your most valuable customers find you.</p>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          {message === "" ?
            <Form className="TaskEditor__form" onSubmit={handleSubmit}>
              <SubmitButton isLoaded={isLoaded} error={error} />
            </Form>
            : <p>{message}</p>}
        </div>
      </div>
    </>
  );
}

export default LTVForm;
