import './style.scss';

function Form(props) {
  return (
    <form className={"Form " + props.className} id={props.id} onSubmit={props.onSubmit}>
      {props.children}
    </form>
  );
}

Form.defaultProps = {
  className: "",
}

export default Form;