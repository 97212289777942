import './style.scss';

function ImpactAnalysis(props) {
  return (
    <div className="ImpactAnalysis">
      {props.children}
    </div>
  );
}

export default ImpactAnalysis;
