import './style.scss';
//import LoadingDots from 'components/atoms/LoadingDots';


function SubmitStatus(props) {
  const { error, isLoaded, parentClass, successMessage } = props;

  if (error) {
    console.log(error);
    return <div className={parentClass + "__status " + parentClass + "__status--error"}>Error: {error.message}</div>
  } else if (isLoaded !== null) {
    if (!isLoaded) {
      return <div className={parentClass + "__status " + parentClass + "__status--loading"}>{/* <LoadingDots className={parentClass + "__loading"} imgAttributes={{height:22}} /> */}</div>
    } else {
      return <div className={parentClass + "__status " + parentClass + "__status--done"}>{successMessage}</div>;
    }
  }
  return "";
}

SubmitStatus.defaultProps = {
  parentClass: "SubmitStatus"
}

export default SubmitStatus;
