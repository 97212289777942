import './style.scss';

function Datasources(props) {
  return (
    <div className="Datasources">
      {props.children}
      {!props.children &&
        <div>Select a data source in the sidebar to continue</div>
      }
    </div>
  );
}

export default Datasources;
