import './style.scss';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="TaskEditor__submit" icon="arrowRight" iconSide="right">Create BigQuery data warehouse</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function WarehouseAdd(props) {
  const [task, setTask] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [, setValidation] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const [cookies] = useCookies(["token", "project"]);
  const nameValidation = /^[a-z][a-z0-9\-]*$/;
  // eslint-disable-next-line no-useless-escape
  const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
  const [warehouseId] = useState(match.params.warehouse_id);

  const validateTask = (name, type) => {
    return nameValidation.test(name) && typeof name !== "undefined" && typeof type !== "undefined" && type.length;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    task.type = 'cogny';

    setValidation(true);

    if (!validateTask(task.name, task.type)) {
      return;
    } 

    setIsLoaded(false);

    const newDatasource = {
      name: task.name,
      type: task.type,
      analytics_email: task.analytics_email,
      promo: task.promo,
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      ReactGA.event({
        category: "UserActions",
        action: "AddWarehouse",
        label: cookies.token,
      });
      api.addWarehouse(warehouseId, newDatasource)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setTask(taskState => ({
              ...taskState,
              ...taskResult
            }));

            history.push("/" + taskResult.warehouse_id + "/datasources");
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  /*
  const connectors = [
  {
    "id": "cogny",
    "name": "Cogny managed",
  },
  {
    "id": "custom",
    "name": "Custom",
  },
];

  const options = connectors.map(c => {
    return {value: c.id, label: c.name, icon: c.icon_url, description: c.description };
  });
  */

  const handleChange = (e) => {
    if (e.target && e.target.id === "TaskEditor__title") {
      setTask(taskState => ({
        ...taskState,
        name: e.target.value
      }));
    } else if (e.target && e.target.id === "TaskEditor__promo") {
      setTask(taskState => ({
        ...taskState,
        promo: e.target.value
      }));
    } else if (e.target && e.target.id === "TaskEditor__analytics_email") {
      setTask(taskState => ({
        ...taskState,
        analytics_email: e.target.value
      }));
    } else {
      /*
      setTask(taskState => ({
        ...taskState,
        type: e.value,
        description: e.description,
        icon: e.icon
      }));
  */
    }
  };

  const Validation = (props) => {
    if (props.id === 'name') {
      if (!nameValidation.test(task.name)) {
        return "Name must only containg lower case letters (a-z) and/or numbers and not start with a number";
      }
    } else if (props.id === 'analytics_email') {
      if (typeof task.analytics_email !== 'undefined' && task.analytics_email !== '' && !emailValidation.test(task.analytics_email)) {
        return "Must be vaild email";
      }
    }
    return "";
  };


  return (
    <>
      <div className="TaskEditor">
        <Form className="TaskEditor__form" onSubmit={handleSubmit}>

          <FieldRow>
            <Field
              type="text"
              id="TaskEditor__title"
              className="TaskEditor__title"
              name="Warehouse Name"
              value={task.name ? task.name : ''}
              onChange={handleChange}
              validation={<Validation id="name" />}
              placeholder="Company/section name"
            />
          </FieldRow>

          <p>Your warehouse project name will be "cogny-{task.name}"</p>

          <FieldRow>
            <Field
              type="text"
              id="TaskEditor__analytics_email"
              className="TaskEditor__title"
              name="Google Analytics User (optional)"
              value={task.analytics_email ? task.analytics_email : ''}
              onChange={handleChange}
              validation={<Validation id="analytics_email" />}
              placeholder="Email address of user who will connect Google Analytics 4"
            />
          </FieldRow>

          <FieldRow>
            <Field
              type="text"
              id="TaskEditor__promo"
              className="TaskEditor__title"
              name="Promo code (optional)"
              value={task.promo ? task.promo : ''}
              onChange={handleChange}
              validation={<Validation id="promo" />}
              placeholder="Optional Promotion code"
            />
          </FieldRow>

          <SubmitButton isLoaded={isLoaded} error={error} />
        </Form>
        {isLoaded ? "" : <>
          <p>The warehouse setup usually takes around less then a minute. Hang in there!</p>
          <LoadingDots />
        </>}
        {error ? <div>{error.message}</div> : ""}
      </div>
    </>
  )
}

export default WarehouseAdd;
