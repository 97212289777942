import React, { useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import "./style.css";

const Menu = ({ onRerun, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleRerun = () => {
    onRerun && onRerun();
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDelete && onDelete();
    setIsOpen(false);
  };

  return (
    <div className="menu-container">
      <button
        className="menu-button menu-icon"
        onClick={handleToggle}
      >
        <FaEllipsisV />
      </button>
      {isOpen && (
        <div className="menu-items menu-items--open">
          <button
            className="menu-item"
            onClick={handleRerun}
          >
            <nobr>Re-run</nobr>
          </button>
          <button
            className="menu-item menu-item--delete"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>)}
    </div>
  );
};

export default Menu;
