import './style.scss';
import React, { useState, useEffect, PureComponent } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import ImpactCard from './components/ImpactCard';
import LoadingDots from 'components/atoms/LoadingDots';
import Button from 'components/atoms/Button';
import { useCookies } from 'react-cookie';
import { useRouteMatch } from 'react-router-dom';
import { LineChart, Line, XAxis, Legend } from 'recharts';

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {((d) => { if (d > 0) { return new Date(d).toISOString().split("T")[0] } })(payload.value)}
          { /* payload.value */}
        </text>
      </g>
    );
  }
}

function ImpactAnalysisList(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [impacts, setImpacts] = useState([]);
  const [series, setSeries] = useState([]);
  const [cookies] = useCookies(["token", "project"]);
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getImpacts(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setImpacts(result);
            var series = result.sort((b, a) => { return new Date(a.start_date).getTime() - new Date(b.start_date).getTime() });
            if (series.length > 7) {
              series = series.slice(0, 7);
            }
            setSeries(
              series.map((r, i) => {
              return [{
                name: "Pre period " + r.name,
                data: [
                  { date: new Date(r.start_date).getTime(), value: -i },
                  { date: new Date(r.change_date).getTime(), value: -i },
                ],
              }, {
                name: r.name,
                data: [
                  { date: new Date(r.change_date).getTime(), value: -i },
                  { date: new Date(r.stop_date).getTime(), value: -i },
                ],
              }];
            }).flat());
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  var colors = [
    "#CC20A6",
    "#CC20A6",
    "#D63B19",
    "#D63B19",
    "#FFA800",
    "#FFA800",
    "#FFD600",
    "#FFD600",
    "#00BD4C",
    "#00BD4C",
    "#00BDBD",
    "#00BDBD",
    "#2283DC",
    "#2283DC",
  ];

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul>
        {
          payload.map((entry, index) => {
            console.log(entry);
            if (!entry.value.startsWith("Pre period")) {
              return <li key={`item-${index}`} style={{ color: entry.color }}>{entry.value}</li>;
            } else {
              return null;
            }
          })
        }
      </ul>
    );
  }

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">Impact Analysis</h1>
          <Button type="link" href={"/" + warehouseId + "/impact/new"} className="TaskList__addTask" icon="plus">New Impact Analysis</Button>
        </div>
        {
          !isLoaded ?
            <LoadingDots />
            :
            <div className="TaskList__box TasksBox--recurring">

              {
                impacts.length && impacts.length > 1 ?
                  <LineChart width={530} height={350} margin={{ top: 5, right: 20, left: 50, bottom: 45 }}>
                    { /* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="date" type="number" domain={['auto', 'auto']} interval={5} minTickGap={15} tick={<CustomizedAxisTick />} scale="time" tickLine={{ height: 150 }} />
                    { /* <YAxis dataKey="value" /> */}
                    { /* <Tooltip /> */}
                    <Legend content={renderLegend} verticalAlign="top" />
                    {series.map((s, i) => (
                      <Line dataKey="value" data={s.data} name={s.name} key={s.name} stroke={colors[i % colors.length]} strokeDasharray={s.name.startsWith("Pre period") ? "3 3" : ""} strokeWidth="6" dot={false} />
                    ))}
                  </LineChart> : ""}
              {
                impacts.length ?
                  impacts.map(ds => {
                    return (
                      <ImpactCard key={ds.id} task={ds} warehouseId={warehouseId} />
                    );
                  })
                  :
                  <p>Make your first impact analysis!</p>
              }
            </div>
        }
      </div>
    );

  }
}

export default ImpactAnalysisList;
