import './style.scss';
import logo from './cogny-logo.svg';

function Logo(props) {
  return (
    <div className={ "Logo " + props.className }>
      <img src={logo} className="Logo__image" alt={ props.alt } />
    </div>
  );
}

Logo.defaultProps = {
  className: "",
};

export default Logo;
