import './style.scss';
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import LoadingDots from 'components/atoms/LoadingDots';
import { useCookies } from 'react-cookie';
import ArrowLink from 'components/molecules/ArrowLink';
import { LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';

function DashboardMain(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [warehouse, setWarehouse] = useState([]);
  const [processing, setProcessing] = useState([]);
  const match = useRouteMatch();
  const [cookies] = useCookies(["token", "project"]);
  const [warehouseId] = useState(match.params.warehouse_id);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouse(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setWarehouse(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseProcessingStats(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setProcessing(result);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  /*
  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseStorageStats(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setStorage(result);
      console.log(result);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
  }, [cookies])
  */

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="TaskList">
        {isLoaded ?
          <div>
            <div className="TaskList__welcome">
              <h1 className="TaskList__title">{warehouse.name}</h1>
            </div>
            {warehouse.status === 'New' ?
              <p>Status: Waiting for new data warehouse to be provisioned.</p>
              : <p></p>}
            {warehouse.status === 'Active' && warehouse.no_datasources === 0 ?
              <div>
                <p>This dashboard will show you your account status at a glance</p>
                <p>To get started, add a few <ArrowLink to={"/" + warehouseId + "/datasources"}>data sources</ArrowLink>.</p>
              </div>
              :
              <div>
              </div>
            }
            {warehouse.status !== 'Active' ?
              <p></p>
              :
              <div>
                <h2>Credit Usage</h2>
                <BarChart width={600} height={250} data={warehouse.credit_usage}>
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip contentStyle={{ 'background': '#444' }} />
                  <Legend />
                  <Bar type="montone" dataKey="processing" stackId="a" fill="#00BDBD" />
                  <Bar type="montone" dataKey="data_sources" stackId="a" fill="#8884d8" />
                </BarChart>

                <h2>Data Processing</h2>
                <LineChart width={600} height={250} data={processing.data}>
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip contentStyle={{ 'background': '#444' }} />
                  <Legend />
                  <Line type="montone" dataKey="GB" stroke="#00BDBD" dot={false} strokeWidth={2} />
                </LineChart>
              </div>
            }
          </div>
          : <LoadingDots />}
      </div>
    );

  }
}

export default DashboardMain;
