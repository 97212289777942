import './style.scss';
import background from './backBg@2x.jpg';
import { ReactComponent as BackLights } from './light.svg';

function OutsideContent(props) {
  const { frontpage, subpage, children, nobg } = props;

  return (
    <main className={"OutsideContent " + (frontpage ? "OutsideContent--frontpage" : "")}>

      {frontpage && (
        <div className="OutsideContent__background" style={{ backgroundImage: "url(" + background + ")" }}>
          <BackLights className="OutsideContent__lights" />
        </div>
      )}

      {subpage && !nobg && (
        <div className="OutsideContent__background" style={{ backgroundImage: "url(" + background + ")", opacity: 0.25 }}></div>
      )}

      <div className="OutsideContent__container">
        {children}
      </div>
    </main>
  );
}

export default OutsideContent;
