import './style.scss';
import loading from './loading.svg';

function LoadingDots(props) {
  return (
    <img className={"LoadingDots " + props.className} src={loading} alt={props.alt} {...props.imgAttributes} />
  );
}

LoadingDots.defaultProps = {
  className: "",
  alt: "",
};

export default LoadingDots;