import './style.scss';
import { ReactComponent as CognyModelFront } from './cognyModelFront2.svg';
import { ReactComponent as CognyModelLines } from './cognyModelLines.svg';
import { ReactComponent as CognyModelFrontSmall } from './cognyModelFrontSmall2.svg';
import { ReactComponent as CognyModelLinesSmall } from './cognyModelLinesSmall.svg';
import OutsideBox from 'outside/components/OutsideBox';
import { ReactComponent as Arrow } from './arrow.svg';
import { useEffect, useState } from 'react';

if (!(CSS.supports("backdrop-filter", "blur(5px)") || CSS.supports("-webkit-backdrop-filter", "blur(5px)"))) {
  var root = document.documentElement;
  root.className += root.className.length ? ' no-backdropfilter' : 'no-backdropfilter';
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function Hero(props) {
  const size = useWindowSize();
  
  return (
    <OutsideBox classBase="Hero">
      <div className="Hero">
        <div className="Hero__top">
          <div className="Hero__inner">
            <h1 className="Hero__title">Unlock faster time to insight and actions</h1>
            <p className="Hero__tagline">Using Cogny's data management platform that anyone can run.</p>
            <div className={"Hero__model" + (size.width < 768 ? " Hero__model--small" : "") }>
              {
                size.width < 768 ?
                <>
                  <CognyModelLinesSmall className="Hero__image Hero__image--back" />
                  <CognyModelFrontSmall className="Hero__image Hero__image--front" />
                </>
                :
                <>
                  <CognyModelLines className="Hero__image Hero__image--back" />
                  <CognyModelFront className="Hero__image Hero__image--front" />
                </>
              }
            </div>
          </div>
        </div>
        <div className="Hero__bottom">
          <a href="https://meetings-eu1.hubspot.com/berner" className="Hero__button">
            <span className="Hero__buttonLabel">Book a demo</span>
            <Arrow className="Hero__buttonArrow" />
          </a>
        </div>
      </div>
    </OutsideBox>
  );
}

export default Hero;
