import './style.scss';
import HomeLink from 'components/molecules/HomeLink';
import Menu from 'components/molecules/Menu';

function Sidebar(props) {
  return (
    <aside className="Sidebar">
      <HomeLink class="Sidebar__home" />
      <Menu class="Sidebar__menu" coinsData={props.coinsData} />
    </aside>
  );
}

export default Sidebar;