import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';

function SubpageHero(props) {
  return (
    <OutsideBox classBase="SubpageHero" nobg>
      <div className="SubpageHero">
        <div className={"SubpageHero__inner" + (props.align === "center" ? " SubpageHero__inner--center" : "")}>
          {props.title && (
            <h1 className="SubpageHero__title">{props.title}</h1>
          )}

          {props.bigtagline && (
            <div className="SubpageHero__bigtagline">{props.bigtagline}</div>
          )}

          {props.tagline && (
            <div className="SubpageHero__tagline">{props.tagline}</div>
          )}

          {props.list && (
            <ul className="SubpageHero__list">
              {props.list.map((item, index) => { return <li key={index}>{item}</li> })}
            </ul>
          )}

          {props.text && (
            <div className="SubpageHero__text">{props.text}</div>
          )}

          {props.button}
        </div>
      </div>
    </OutsideBox>
  );
}

export default SubpageHero;