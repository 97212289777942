import './style.scss';
import { ReactComponent as Arrow } from './arrow.svg';

function OutsideCTA(props) {
  return (
    <a href={props.href} className="OutsideCTA">
      <span className="OutsideCTA__label">{props.label}</span>
      <Arrow className="OutsideCTA__arrow" />
    </a>
  );
}

export default OutsideCTA;