import './style.scss';
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CognyAPI from 'components/_classes/CognyAPI';
import CognyTask from 'components/_classes/CognyTask';
import Notification from 'components/molecules/Notification';
import LoadingDots from 'components/atoms/LoadingDots';
import BackLink from 'components/molecules/BackLink';
import Tabs from 'components/molecules/Tabs';
import Icon from 'components/atoms/Icon';
import Title from 'components/atoms/Title';
import StatusPill from 'components/atoms/StatusPill';
import CognyStatus from 'components/_classes/CognyStatus';
import Tags from 'components/molecules/Tags';
import TaskLog from 'components/molecules/TaskLog';
import Header from 'components/molecules/Header';

function TaskDetails(props) {
  const [task, setTask] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [notification, setNotification] = useState({});
  const match = useRouteMatch();
  const [cookies] = useCookies(["token","project"]);
  const [gid] = useState(match.params.taskId);

  useEffect(() => {
    let mounted = true;
    const api = new CognyAPI(cookies.token);
    let project = cookies.project;
    api.getTask(project, gid)
      .then(
        (result) => {
          if (!mounted) return;
          const cognyTask = new CognyTask(result);
          setTask(cognyTask);
          setIsLoaded(true);
        },
        (error) => {
          if (!mounted) return;
          setError(error);
          setIsLoaded(true);
        }
      );
    return () => {
      mounted = false;
    }
  },[gid, cookies.project, cookies.token]);

  useEffect(() => {
    if (typeof task.getNotification !== "function") return;
    const taskNotification = task.getNotification();
    setNotification(taskNotification);
  }, [task])

  const handleActions = (e) => {
    e.preventDefault();
    const nextStatus = new CognyStatus(task.getStatus()).nextStatus()

    if (task.isStatus(1) && nextStatus) {
      setTask(taskState => ({
        ...taskState,
        status: nextStatus
      }));
    } else if (task.isStatus(4)) {
      setTask(taskState => ({
        ...taskState,
        status: nextStatus
      }));
    }
  }

  if (error) {
    console.log(error);
    return <div>{error.message}</div>;
  } else {
    let subpath = typeof match.params.tabId !== "undefined" ? match.params.tabId : false;

    return (
      <>
        <Header>
          <BackLink to="/tasks" />
        </Header>
        
        {
          !isLoaded && !(task instanceof CognyTask) ?
          
            <LoadingDots />
          :
            <div className="TaskDetails">

              <Notification 
                id={notification.id} 
                message={notification.message} 
                action={notification.action} 
                onClick={handleActions}
              />
              
              <div className="TaskDetails__header">
                <Title className="TaskDetails__title">{task.getTitle()}</Title>
                <StatusPill status={task.getStatus()} />
              </div>

              <Tags className="TaskDetails__tags" tags={task.getTags()} />

              <Tabs active={subpath} basepath={'/task/' + gid + '/'}>
                <div icon={<Icon icon="clipboardCheck" className="Tab__Icon" />} label="Request" slug="request">
                  <div className="TaskDetails__notes">
                    {
                      task.getNotes() ?
                        task.getNotes()
                      :
                        <div className="TaskDetails__notesMissing">This task has no notes</div>
                    }
                  </div>
                </div>
                <div icon={<Icon icon="approve" className="Tab__Icon" />} label="Activity log" slug="log">
                  <TaskLog task={task} />
                </div>
                {
                  task.hasReport() ?
                    <div icon={<Icon icon="answered" className="Tab__Icon" />} label="Report" slug="report">
                      <p>{task.getReport()}</p>
                    </div>
                  :
                    false
                }
              </Tabs>
            </div>
        }
      </>
    );
  }
}

export default TaskDetails;
