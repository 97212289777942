import './style.scss';

function Coins(props) {
  return (
    <div className="Coins">
      {props.children}
    </div>
  );
}

export default Coins;
