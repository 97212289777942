import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
//import Button from 'components/atoms/Button';
import { useCookies } from 'react-cookie';
import { useRouteMatch } from 'react-router-dom';
//import { Elements } from '@stripe/react-stripe-js';
//import { PaymentElement } from '@stripe/react-stripe-js';

//import { loadStripe } from '@stripe/stripe-js/pure';

function SettingsBilling(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [stripeOptions, setStripeOptions] = useState([]);
  const [billingData, setBillingData] = useState({});
  const [cookies] = useCookies(["token"]);
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  /* stripe stuff */
  //const stripePromise = loadStripe('pk_test_51MZUmrAa8spvFl9x0LIzB8xMHKr53lMyekSpKKI9fnCvhigbMG35da9J13NgX09laWF6itgeJsScc8KIEhyBjMFz0024e1hP27');
  //const stripePromise = loadStripe('pk_live_51MZUmrAa8spvFl9xQK1UL6Z9ZtQGtPMCJUcb1KvFFW9jLWEb6cua74VsnZzPP9i1BSi2TesNGBHUX8hdRy2OnmoR00jryVeRIt');

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getBilling(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
	    setBillingData(result);
	    console.log(result);
            //setStripeOptions(
            //  {
            //    clientSecret: result.client_secret,
            //  }
            //);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="TaskList">
        {/*
          <div className="TaskList__welcome">
            <h1 className="TaskList__title">Billing</h1>
	    { /* <Button type="link" href={"/" + warehouseId + "/access/new"} className="TaskList__addTask" icon="plus">Add new user</Button> * / }
          </div> */ }
	  <div>
	    { isLoaded ? 
		    <>
		    { billingData.billing_type === "stripe" ? 
		    <div>
		    { /* 
		    <Elements stripe={loadStripe(billingData.skey)} options={stripeOptions}> 
	      		<PaymentElement />
	    	    </Elements> */ }
		    <stripe-pricing-table pricing-table-id={billingData.p_table} publishable-key={billingData.skey} client-reference-id={warehouseId}>
    		    </stripe-pricing-table>
		    </div>
		    : 
		    <div>
			<h2>Invoice billing</h2>
			<p>Contact invoice@cogny.com if you have any questions.</p>
		    </div>
		    }
		    </>
	    : "" }
	  </div> 
	</div>
    );
  }
}

export default SettingsBilling;
