import "./style.scss";
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CognyAPI from 'components/_classes/CognyAPI';
import { NavLink } from 'react-router-dom';
import LoadingDots from 'components/atoms/LoadingDots';

const TransformationsListSection = (props) => {
  const { list, type, title } = props;
  const filteredList = list.filter((x) => { return x.type === type });
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  filteredList.sort(function (a, b) {
    var textA = a.updated_at;
    var textB = b.updated_at;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  const reversedList = filteredList.reverse();

  const masterList = reversedList;

  return (
    <div className="TransformationsList__section">
      <div className="TransformationsList__sectionHeader">
        <h2 className="TransformationsList__title">{title}</h2>
        <div className="TransformationsList__count">{masterList.length}</div>
      </div>
      <div className="TransformationsList__items">
        {masterList.map((item, index) => (
          <NavLink
            className="TransformationsList__item"
            to={"/" + warehouseId + "/terminal/" + item.id}
            key={index + item.name}
            activeClassName="TransformationsList__item--current"
          >
            <div className="TransformationsList__label">{item.name}</div>
            <div className="TransformationsList__meta">{item.updated_at}</div>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

function TransformationsList(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [, setError] = useState(null);
  const [xforms, setXForms] = useState([]);
  const [cookies] = useCookies(["token"]);
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getXForms(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setXForms(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
            setIsLoaded(true);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  if (!isLoaded) return (
    <div className="TransformationsList">
      <div className="TransformationsList__loading">
        <LoadingDots />
      </div>
    </div>
  );

  return (
    <div className="TransformationsList">
      {
        xforms && xforms.length > 0 &&
        <div className="TransformationsList__inner">
          <TransformationsListSection title="Metrics" type="metric" list={xforms} />
          <TransformationsListSection title="Transformations" type="xform" list={xforms} />
          <TransformationsListSection title="Explorations" type="custom" list={xforms} />
        </div>
      }
    </div>
  );
}

export default TransformationsList;