import './style.scss';
import Button from 'components/atoms/Button';
import CognyAPI from 'components/_classes/CognyAPI';
import SubmitStatus from 'components/molecules/SubmitStatus';
import { useRouteMatch } from 'react-router-dom';
import React, { useState } from 'react';
import Form from 'components/atoms/Form';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="mmm-get-started">Get started with Looker</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function MMMForm(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [cookies] = useCookies(["token", "project"]);
  const [message, setMessage] = useState("");
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoaded(false);

    const newFeature = {
      feature: "looker",
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      ReactGA.event({
        category: "UserActions",
        action: "AddFeatureLooker",
        label: cookies.token,
      });
      api.addWarehouseFeature(warehouseId, newFeature)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setMessage("You have been added to the Looker implementation waitlist, we'll be in touch!");
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
  }

  return (
    <>
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">Data Exploration <span className="TaskList__logo">(beta)</span></h1>
        </div>

        <img src="/images/looker.gif" width="300" align="right" style={{ padding: 20 + 'px' }} alt="Data exploration using Looker" />
        <p>The most common BI analys task is to "deep dive" in some specific segment of a report, to help stakeholders better understand a business outcome.</p>
        <p>In traditional setups this process is costly and slow and by leveraging the modern data warehouse and modern data exploration tools, we'll help you democretize your data and enable your stakeholders to find their own answers. Without sacrificing data quality or governance.</p>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          {message === "" ?
            <Form className="TaskEditor__form" onSubmit={handleSubmit}>
              <SubmitButton isLoaded={isLoaded} error={error} />
            </Form>
            : <p>{message}</p>}
        </div>
      </div>
    </>
  );
}

export default MMMForm;
