import './style.scss';
import OutsideBox from '../OutsideBox';
import { ReactComponent as Arrow } from './arrow.svg';

function BookADemo(props) {
  return (
    <OutsideBox classBase="BookADemo">
      <div className="BookADemo">
        <div className="BookADemo__content">
          <h2 className="BookADemo__title">Find out more?</h2>
          <p className="BookADemo__text">We’d love to get the chance to demo Cogny in person.</p>
        </div>
        <a href="https://meetings-eu1.hubspot.com/berner" className="BookADemo__button">
          <span className="BookADemo__buttonLabel">Book a demo</span>
          <Arrow className="BookADemo__buttonArrow" />
        </a>
      </div>
    </OutsideBox>
  );
}

export default BookADemo;
