import './style.scss';

function Dashboard(props) {
  return (
    <div className="Dashboard">
      {props.children}
    </div>
  );
}

export default Dashboard;
