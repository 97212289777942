import './style.scss';

function DataTerminal(props) {
  return (
    <div className="DataTerminal">
      {props.children}
    </div>
  );
}

export default DataTerminal;
