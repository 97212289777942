import './style.scss';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import { useRouteMatch } from 'react-router-dom';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import BackLink from 'components/molecules/BackLink';
import Header from 'components/molecules/Header';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="TaskEditor__submit" icon="plus">Add user</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function SettingsAddUser(props) {
  const [task, setTask] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [/*validation*/, setValidation] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const [cookies] = useCookies(["token", "project"]);
  const nameValidation = /.+@.+\..+/;
  const [warehouseId] = useState(match.params.warehouse_id);

  const validateTask = (name, role) => {
    return typeof nameValidation.test(name) && typeof role !== "undefined" && role.length;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidation(true);

    if (!validateTask(task.email, task.role)) {
      return;
    }

    setIsLoaded(false);

    const newDatasource = {
      email: task.email,
      role: task.role,
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.addAccess(warehouseId, newDatasource)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setTask(taskState => ({
              ...taskState,
              ...taskResult
            }));

            history.push("/" + warehouseId + "/settings");
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  const connectors = [
    {
      "id": "Read",
      "name": "Read",
    },
    {
      "id": "Write",
      "name": "Write",
    },
    {
      "id": "Owner",
      "name": "Owner",
    },
  ];

  const options = connectors.map(c => {
    return { value: c.id, label: c.name };
  });

  const handleChange = (e) => {
    if (e.target && e.target.id === "TaskEditor__title") {
      setTask(taskState => ({
        ...taskState,
        email: e.target.value
      }));
    } else {
      setTask(taskState => ({
        ...taskState,
        role: e.value,
      }));
    }
  };

  const Validation = (props) => {
    if (props.id === 'email') {
      if (!nameValidation.test(task.email)) {
        return "Must be valid email";
      }
    }
    return "";
  };


  return (
    <>
      <Header>
        <BackLink to={"/" + warehouseId + "/settings"} />
      </Header>

      <div className="TaskEditor">
        <Form className="TaskEditor__form" onSubmit={handleSubmit}>
          <FieldRow>
            <Field
              type="text"
              id="TaskEditor__title"
              className="TaskEditor__title"
              name="Email"
              value={task.email ? task.email : ''}
              onChange={handleChange}
              validation={<Validation id="email" />}
              placeholder="Email, must be google account"
            />
          </FieldRow>

          <FieldRow>
            <Field
              type="select"
              id="TaskEditor__tags"
              className="TaskEditor__tags"
              name="Role"
              options={options}
              onChange={handleChange}
              classNamePrefix="Select"
              placeholder="Select role..."
            />
          </FieldRow>

          <SubmitButton isLoaded={isLoaded} error={error} />
        </Form>
        {isLoaded ? "" : <LoadingDots />}
        {error ? <div>{error.message}</div> : ""}
      </div>
    </>
  )
}

export default SettingsAddUser;
