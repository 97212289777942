import './style.scss';

function Header(props) {
  return (
    <div className="Header">
      { props.children }
    </div>
  );
}

export default Header;