import './style.scss';
import Button from 'components/atoms/Button';
import CognyAPI from 'components/_classes/CognyAPI';
import SubmitStatus from 'components/molecules/SubmitStatus';
import { useRouteMatch } from 'react-router-dom';
import React, { useState } from 'react';
import Form from 'components/atoms/Form';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="mmm-get-started">Get started with Audiences</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function PersonaForm(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [cookies] = useCookies(["token", "project"]);
  const [message, setMessage] = useState("");
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoaded(false);

    const newFeature = {
      feature: "persona",
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      ReactGA.event({
        category: "UserActions",
        action: "AddFeaturePersona",
        label: cookies.token,
      });
      api.addWarehouseFeature(warehouseId, newFeature)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setMessage("You have been added to the Persona Modelling / Audiences waitlist, we'll be in touch!");
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
  }

  return (
    <>
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">Persona modelling <span className="TaskList__logo">(beta)</span></h1>
        </div>

        <img src="/images/personas_sample.jpeg" width="300" align="right" style={{ padding: 20 + 'px' }} alt="typical persona profile" />
        <p>Knowing your customers used to be fairly easy in the days of small scale physical stores, think a car dealership. As the customer walks in the sales guy has a pretty good idea of what kind of car the person is interested in and can line up the sales process based on that.</p>
        <p>In the digital world, this process can be replicated by using large amounts of your own 1st party data combined with other data sources. We call this persona modelling and it will give you a powerful tool to sharpen your marketing and sales efforts.</p>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          {message === "" ?
            <Form className="TaskEditor__form" onSubmit={handleSubmit}>
              <SubmitButton isLoaded={isLoaded} error={error} />
            </Form>
            : <p>{message}</p>}
        </div>
      </div>
    </>
  );
}

export default PersonaForm;
