import LoadingDots from 'components/atoms/LoadingDots';
import './style.scss';

function Tags(props) {
  const tags = props.tags;

  if (typeof tags === "undefined") {
    return <LoadingDots className="Tags__loading" alt="" />;
  }

  return (
    <div className={"Tags " + props.className }>
      
      <ul className="Tags__list">
        {tags.map((tag) => {
          return (
            <li key={tag.gid} className="Tags__tag">{tag.name}</li>
          )
        })}
      </ul>
    </div>
  );
}

Tags.defaultProps = {
  className: "",
};

export default Tags;
