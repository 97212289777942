import './style.scss';
import { ReactComponent as IconAnswered } from './assets/answered.svg';
import { ReactComponent as IconApprove } from './assets/approve.svg';
import { ReactComponent as IconArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as IconArrowRight } from './assets/arrow-right.svg';
import { ReactComponent as IconCardMoveFrom } from './assets/card-move-from.svg';
import { ReactComponent as IconClipboardCheck } from './assets/clipboard-check.svg';
import { ReactComponent as IconCoins } from './assets/coins.svg';
import { ReactComponent as IconCross } from './assets/cross.svg';
import { ReactComponent as IconEstimate } from './assets/estimate.svg';
import { ReactComponent as IconMenu } from './assets/menu.svg';
import { ReactComponent as IconPriority } from './assets/priority.svg';
import { ReactComponent as IconRequest } from './assets/request.svg';
import { ReactComponent as IconSettings } from './assets/settings.svg';
import { ReactComponent as IconUser } from './assets/user.svg';
import { ReactComponent as IconPlus } from './assets/plus.svg';
import { ReactComponent as IconHome } from './assets/home.svg';
import { ReactComponent as IconDashboard } from './assets/dashboard.svg';
import { ReactComponent as IconDatasource } from './assets/datasource.svg';
import { ReactComponent as IconTerminal } from './assets/terminal.svg';
import { ReactComponent as IconAttribution } from './assets/attribution.svg';
import { ReactComponent as IconDataVis } from './assets/datavis.svg';
import { ReactComponent as IconMMM } from './assets/mmm.svg';
import { ReactComponent as IconImpact } from './assets/impact.svg';
import { ReactComponent as IconSort } from './assets/sort.svg';

function Icon(props) {
  const icons = {
    impact: IconImpact,
    mmm: IconMMM,
    datavis: IconDataVis,
    attribution: IconAttribution,
    terminal: IconTerminal,
    datasources: IconDatasource,
    dashboard: IconDashboard,
    home: IconHome,
    plus: IconPlus,
    user: IconUser,
    settings: IconSettings,
    request: IconRequest,
    priority: IconPriority,
    menu: IconMenu,
    estimate: IconEstimate,
    cross: IconCross,
    coins: IconCoins,
    clipboardCheck: IconClipboardCheck,
    cardMoveFrom: IconCardMoveFrom,
    arrowRight: IconArrowRight,
    arrowLeft: IconArrowLeft,
    approve: IconApprove,
    answered: IconAnswered,
    sort: IconSort,
  }

  /**
   * Get component name at runtime
   * https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
   */
  const TheRightIconComponent = (props) => {
    const ComponentName = icons[props.icon];
    return <ComponentName className={"Icon Icon--" + props.icon + " " + props.className} />
  };

  return <TheRightIconComponent {...props} />;
}

Icon.defaultProps = {
  icon: IconPlus,
  className: "",
};

export default Icon;
