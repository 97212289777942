import './style.scss';
import React, { useState } from 'react';
//import TaskStatus from 'components/atoms/TaskStatus';
import { useRouteMatch } from 'react-router-dom';
import Tabs from 'components/molecules/Tabs';
import Icon from 'components/atoms/Icon';
import SettingsUsers from 'components/organisms/SettingsUsers';
import SettingsBilling from 'components/organisms/SettingsBilling';

function SettingsPage(props) {
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  var subpath = "users";

  return (
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">Settings</h1>
        </div>
        <Tabs active={subpath} basepath={'/'+warehouseId+'/settings/'}>
          <div icon={<Icon icon="user" className="Tab__Icon" />} label="Users" slug="users">
          	<SettingsUsers />
          </div>
          <div icon={<Icon icon="approve" className="Tab__Icon" />} label="Billing" slug="billing">
          <SettingsBilling />
          </div>
        </Tabs>
      </div>
  );
}

export default SettingsPage;
