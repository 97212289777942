import './style.scss';
import { NavLink } from 'react-router-dom';

function MenuItem(props) {
  return (
    <NavLink className={"MenuItem " + props.className} activeClassName="MenuItem--active" to={props.href} onClick={props.onClick}>{props.label || props.children}</NavLink>
  );
}

export default MenuItem;
