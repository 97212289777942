import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.scss';
import AuthRouter from 'AuthRouter';
import reportWebVitals from 'reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router basename={'/'}>
      <CookiesProvider>
        <HelmetProvider>
          <AuthRouter />
        </HelmetProvider>
      </CookiesProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
