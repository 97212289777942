import SubpageHero from 'outside/components/SubpageHero';
import './style.scss';

function NotFound() {
  return (
    <div className="NotFound">
      <SubpageHero
        title="Page not found" 
        tagline="Somehow you've reached a URL that doesn't exist."
      />
    </div>
  );
}

export default NotFound;