import './style.scss';
import Title from 'components/atoms/Title';
import Header from 'components/molecules/Header';
import SectionHeader from 'components/molecules/SectionHeader';
import Field from 'components/molecules/Field';
import ArrowLink from 'components/molecules/ArrowLink';

function FieldRow(props) {
  return (
    <div className="FieldRow AccountForm__row">
      {props.children}
    </div>
  );
}

function AccountForm(props) {
  const handleChange = () => {
    return false;
  }

  return (
    <>
      <Header></Header>

      <div className="AccountForm">
        <SectionHeader className="AccountForm__header">
          <Title>Account</Title>
        </SectionHeader>

        <div className="AccountForm__rows">
          <FieldRow>
            <Field 
              type="text" 
              id="first-name" 
              name="First name" 
              value="Johan" 
              onChange={handleChange} 
              disabled
            />
            <Field 
              type="text" 
              id="last-name" 
              name="Last name" 
              value="Johansson" 
              onChange={handleChange} 
              disabled
            />
          </FieldRow>

          <FieldRow>
            <Field 
              type="text" 
              id="role" 
              name="Role" 
              value="Product manager" 
              onChange={handleChange} 
              disabled
            />
            <Field 
              type="text" 
              id="organisation" 
              name="Organisation" 
              value="Growth Hackers STHLM" 
              onChange={handleChange}
              disabled
            />
          </FieldRow>

          <FieldRow>
            <Field 
              type="email" 
              id="email" 
              name="Email" 
              value="johan.johansson@growthhackers.se" 
              onChange={handleChange}
              disabled
            />
          </FieldRow>
        </div>

        <ArrowLink to="/contact">Contact us to change your account information</ArrowLink>
      </div>
    </>
  );
}

export default AccountForm;