import './style.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import Title from 'components/atoms/Title';
import Input from 'components/atoms/Input';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import Textarea from 'components/atoms/Textarea';
import Label from 'components/atoms/Label';
import SubmitStatus from 'components/molecules/SubmitStatus';
import Unit from 'components/atoms/Unit';
import Header from 'components/molecules/Header';
import BackLink from 'components/molecules/BackLink';
import SectionHeader from 'components/molecules/SectionHeader';

const AddCoinsRow = (props) => {
  const className = (!props.id) ? "" : "AddCoins__row--" + props.id;
  return <div key={props.id} className={"AddCoins__row " + className}>{props.children}</div>;
}

const LocalCurrency = () => {
  return "SEK";
}

const sumAfterExchange = (n) => { return (n) ? Math.round(n * 170) : 0 };

function AddCoins(props) {
  const [amount, setAmount] = useState(100);
  const [notes, setNotes] = useState("");
  const [terms, setTerms] = useState(false);
  const [addCoinsIsLoaded, setAddCoinsIsLoaded] = useState(null);
  const [addCoinsError, setAddCoinsError] = useState(null);
  const [validation, setValidation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidation(true);
    if (!amount || !terms) return;

    setAddCoinsIsLoaded(false);

    const postData = {
      hello: "hello"
    };

    const api = new CognyAPI();
    api.addCoins(postData)
      .then(
        (result) => {
          setAddCoinsIsLoaded(true);
        },
        (error) => {
          setAddCoinsIsLoaded(true);
          setAddCoinsError(error);
        }
      );
  }

  const Validation = (props) => {
    if (validation) {
      if (!amount && props.id === "amount") {
        return <div className="AddCoins__validation AddCoins__validation--invalid">You need to enter an amount</div>;
      } else if (!terms && props.id === "terms") {
        return <div className="AddCoins__validation AddCoins__validation--invalid">You need to accept the terms</div>;
      }
    }
    return "";
  };

  return (
    <>
      <Header>
        <BackLink to="/coins" />
      </Header>

      <div className="AddCoins">
        <SectionHeader className="AccountForm__header">
          <Title>Add more coins</Title>
        </SectionHeader>
        
        <Form onSubmit={handleSubmit}>

          <AddCoinsRow id="amount">
            <Label className="AddCoins__label" for="amount" label="Coins" />
            <Input control className="AddCoins__input" name="amount" id="amount" type="number" onChange={(e) => setAmount(e.target.valueAsNumber)} inputAttributes={{step:100,min:0}} value={amount} />
            <Validation id="amount" />
          </AddCoinsRow>
          
          <AddCoinsRow id="exchangeRate">
            <Label className="AddCoins__label" for="exchangeRate" label="Will be added to your next invoice" />
            <Unit id="exchangeRate" unit={<LocalCurrency />} unitSide="after" parentClass="AddCoins">
              <Input className="AddCoins__input" name="exchangeRate" id="exchangeRate" disabled>{sumAfterExchange(amount)}</Input>
            </Unit>
          </AddCoinsRow>

          <AddCoinsRow id="notes">
            <Label className="AddCoins__label" for="notes" label="Message" />
            <Textarea className="AddCoins__textarea" name="notes" id="notes" onChange={(e) => setNotes(e.target.value)}>{notes}</Textarea>
          </AddCoinsRow>

          <AddCoinsRow id="terms">
            <Input required className="AddCoins__input--checkbox" type="checkbox" name="terms" id="terms" onChange={(e) => setTerms(e.target.checked)} />
            <Label className="AddCoins__label--checkbox" for="terms">I have read our <Link to="/terms" className="AddCoins__termsLink">terms & conditions</Link> and accept an extra charge of {sumAfterExchange(amount)} <LocalCurrency /></Label>
            <Validation id="terms" />
          </AddCoinsRow>

          <AddCoinsRow id="submit">
            <Button className="AddCoins__submitButton" type="submit" icon="arrowRight" iconSide="right">Send request</Button>

            <SubmitStatus isLoaded={addCoinsIsLoaded} error={addCoinsError} parentClass="AddCoins" successMessage="Coins have been added to your account!" />
          </AddCoinsRow>

        </Form>
      </div>
    </>
  );
}

export default AddCoins;