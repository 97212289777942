import './style.scss';
import Hero from 'outside/components/Hero';
import TimeSpent from 'outside/components/TimeSpent';
import BookADemo from 'outside/components/BookADemo';
import TrustedBy from 'outside/components/TrustedBy';

function Frontpage() {
  return (
    <div className="Frontpage">
      <Hero />
      <TimeSpent />
      <BookADemo />
      <TrustedBy />
      { /*
      <ProductShowcase product="1" />
      <ProductShowcase product="2" />
      */ }
    </div>
  );
}

export default Frontpage;
