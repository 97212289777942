import './style.scss';

function MMM(props) {
  return (
    <div className="Account">
      {props.children}
    </div>
  );
}

export default MMM;
