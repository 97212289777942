import camelcase from 'camelcase';
import { useState } from 'react';
import './style.scss';

function Priority(props) {
  const { task, className } = props;
  const [prio, setPrio] = useState(task.getPriority());
  const name = "prio" + task.getGid()

  const handleChange = (e) => {
    setPrio(parseInt(e.target.value));
  }

  const levels = ['Low', 'Medium', 'High'];

  return (
    <div className={"Priority " + className }>
      <fieldset className="Priority__fieldset">
        <legend className="Priority__legend">Priority</legend>
        {
          levels.map((level, index) => {
            const value = index + 1;
            return (
              <div className="Priority__level" key={level}>
                <input 
                  className="Priority__radio"
                  type="radio" 
                  id={name + "_" + index}
                  name={name}
                  value={value}
                  checked={prio===value}
                  onChange={handleChange}
                />
                <label htmlFor={name + "_" + index} className={"Priority__label Priority__label--" + camelcase(level)}>{level}</label>
              </div>
            );
          })
        }
      </fieldset>
    </div>
  );
}

Priority.defaultProps = {
  className: "",
};

export default Priority;