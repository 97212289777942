import './style.scss';
import MediaBox from 'outside/components/MediaBox';
import SubpageHero from 'outside/components/SubpageHero';
//import { TypeAnimation } from 'react-type-animation';
import OutsideCTA from 'outside/components/OutsideCTA';

const TypeAnimation = () => {
  return <></>
}

function Mission() {
  return (
    <div className="Mission">
      <SubpageHero
        title="Our mission"
        tagline={
          <TypeAnimation
            sequence={[
              ' ',
              500,
              'Make the world more data-driven.',
              2000, // Waits 2s
            ]}
            wrapper="div"
            cursor={false}
            repeat={false}
          />
        }
        text={
          <>
            <TypeAnimation
              sequence={[
                ' ',
                4000,
                'Combine your data from Hubspot',
                1000, // Waits 2s
                'Combine your data from SalesForce',
                1000, // Waits 2s
                'Combine your data from Shopify',
                1000, // Waits 2s
                'Combine your data from Google Analytics 4', // Types 'Three' without deleting 'Two'
                1000,
                'Combine your data from Google Analytics 4 + Universal Analytics', // Types 'Three' without deleting 'Two'
                1000, // Waits 2s
              ]}
              wrapper="div"
              cursor={false}
              repeat={false}
            />

            <TypeAnimation
              sequence={[
                ' ',
                17000,
                'Leverage your own data and AI to query',
                1000,
                'Leverage your own data and AI to explore',
                1000,
                'Leverage your own data and AI to transform',
                1000,
                'Leverage your own data and AI to analyze',
                1000,
                'Leverage your own data and AI to activate',
                1000,
                'Leverage your own data and AI to optimize',
                1000,
              ]}
              wrapper="div"
              cursor={false}
              repeat={false}
            />
          </>
        }
        button={<OutsideCTA label="Book a demo" href="https://meetings-eu1.hubspot.com/berner" />}
      />

      <MediaBox
        title="Get started with BigQuery in just a few clicks"
        text="Using Cogny's data management platform that anyone can run."
        video="1gPiPCnfPak"
      />

      <MediaBox
        title="Use your data in Looker Studio"
        text="Using Cogny's data management platform that anyone can run."
        video="Lq0cT2u2rW8"
      />

      <MediaBox
        title="Connect your Google Universal Analytics property"
        text="This way you can build reports comparing with historic data from before you implemented Google Analytics 4, give that you had Google Universal Analytics installed."
        video="UyIg5QAMxBQ"
      />
    </div>
  );
}

export default Mission;
