import './style.scss';
import { useCookies } from 'react-cookie';

function SignOutLink(props) {
  const [, , removeCookie] = useCookies(["token"]);

  const handleSignOut = (e) => {
    e.preventDefault();

    removeCookie("token", {sameSite: "strict"});
  }

  return (
    <a href="/" className={"SignOutLink " + props.className} onClick={handleSignOut}>{props.label || props.children}</a>
  );
}

SignOutLink.defaultProps = {
  className: "",
  children: "Sign out",
};

export default SignOutLink;
