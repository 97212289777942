import './style.scss';
import { Link } from 'react-router-dom';
import Icon from 'components/atoms/Icon';

function ArrowLink(props) {
  return (
    <Link to={props.to} className="ArrowLink">
      <span className="ArrowLink__label">{props.children}</span>
      <Icon icon="arrowRight" className="ArrowLink__icon" />
    </Link>
  );
}

ArrowLink.defaultProps = {
  to: '',
};

export default ArrowLink;