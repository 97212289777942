import './style.scss';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import CognyMeta from 'components/_classes/CognyMeta';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import BackLink from 'components/molecules/BackLink';
import Header from 'components/molecules/Header';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button className="TaskEditor__submit" icon="arrowRight" iconSide="right">Submit request</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function TaskEditor(props) {
  const [task, setTask] = useState({});
  const [error] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [validation, setValidation] = useState(false);
  const [cookies] = useCookies(["token","project"]);
  const history = useHistory();

  /*
      gid: "",
      name: "",
      status: "",
      created_by: this.emptyUser,
      created_at: "",
      assigned_to: this.emptyUser,
      assigned_at: "",
      cost: "",
      recurring_cost: "",
      tags: [],
      priority: "",
      approved_by: this.emptyUser,
      approved_at: "",
      answered_by: this.emptyUser,
      answered_at: "",
      notes: "",
  */

  useEffect(() => {
    const emptyTask = CognyMeta.emptyTask;

    const newTask = {
      ...emptyTask,
      status: "New"
    };

    setTask(oldState => ({
      ...oldState,
      ...newTask
    }));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const validateTask = (name, notes) => {
    return typeof name !== "undefined" && name.length && typeof notes !== "undefined" && notes.length;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidation(true);

    if (!validateTask(task.name, task.notes)) {
      return;
    }

    isLoaded(false);

    const newTask = {
      name: task.name,
      notes: task.notes,
    };

    const api = new CognyAPI(cookies.token);
    let project = cookies.project;
    api.addTask(project, newTask)
      .then(
        (taskResult) => {
          setIsLoaded(true);
          setTask(taskState => ({
            ...taskState,
            ...taskResult
          }));
          
          history.push("/task/" + taskResult.gid);
        },
        (error) => {
          isLoaded(true);
          error(error);
        }
      )
  }

  const handleChange = (e) => {
    if (e.target.id === "TaskEditor__title") {
      setTask(taskState => ({
        ...taskState,
        name: e.target.value
      }));
    } else if (e.target.id === "TaskEditor__description") {
      setTask(taskState => ({
        ...taskState,
        notes: e.target.value
      }));
    }
  }

  const handleSelect = (options) => {
    //Renames react-select keynames (value/label) to task object key names (gid/name)
    let newOptions = options;

    options.forEach(function(option, index, options) {
      newOptions[index]['gid'] = option.value;
      newOptions[index]['name'] = option.label;
    });


    setTask(taskState => ({
      ...taskState,
      tags: newOptions
    }));
  }

  const options = [
    { value: 'report', label: 'Report' },
    { value: 'growth', label: 'Growth' },
    { value: 'sales', label: 'Sales' },
    { value: 'ads', label: 'Ads' },
    { value: 'churn', label: 'Churn' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'app', label: 'App' },
    { value: 'web', label: 'Web' },
    { value: 'data', label: 'Data' },
  ];

  const Validation = (props) => {
    if (validation) {
      if ((typeof task.name === "undefined" || !task.name.length) && props.id === "name") {
        return <div className="TaskEditor__validation TaskEditor__validation--invalid">You need to enter a title</div>;
      } else if ((typeof task.notes === "undefined" || !task.notes.length) && props.id === "notes") {
        return <div className="TaskEditor__validation TaskEditor__validation--invalid">You need to enter a description</div>;
      }
    }
    return "";
  };

  if (error) {
    console.log(error);
  }
  return (
    <>
      <Header>
        <BackLink to="/tasks" />
      </Header>

      <div className="TaskEditor">
        <Form className="TaskEditor__form" onSubmit={handleSubmit}>
          <FieldRow>
            <Field 
              type="text" 
              id="TaskEditor__title" 
              className="TaskEditor__title"
              name="Title" 
              value={task.name ? task.name : ''}
              onChange={handleChange}
              validation={<Validation id="name" />}
              placeholder="Enter title"
            />
          </FieldRow>

          <FieldRow>
            <Field 
              type="multiselect"
              id="TaskEditor__tags"
              className="TaskEditor__tags"
              name="Tags"
              options={options}
              onChange={handleSelect}
              classNamePrefix="Select"
              placeholder="Select tags..."
            />
          </FieldRow>

          <FieldRow>
            <Field 
              type="textarea" 
              id="TaskEditor__description" 
              className="TaskEditor__description"
              placeholder="Enter a description"
              name="Description" 
              value={task.notes ? task.notes : ''}
              onChange={handleChange}
              validation={<Validation id="notes" />}
            />
          </FieldRow>

          <SubmitButton isLoaded={isLoaded} error={error} />
        </Form>
        { isLoaded ? "" : <LoadingDots /> }
        { error ? <div>{error.message}</div> : "" }
      </div>
    </>
  )
}

export default TaskEditor;
