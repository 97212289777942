import Button from 'components/atoms/Button';
import TransformationsList from 'components/organisms/Terminal/components/TransformationsList';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

function TerminalSidebar() {
  const match = useRouteMatch();
  const project = match.params.warehouse_id;

  return (
    <div className="TerminalSidebar">
      <Button type="link" href={"/" + project + "/terminal/"} className="TerminalSidebar__new" icon="plus">New exploration</Button>
      <TransformationsList />
    </div>
  )
}

export default TerminalSidebar;