/**
 * A class for handling all task stuff
 */
class CognyCoins {
  /**
   * Get coin history
   * @returns {Object} - Coin history
   */
  getCoinHistory = () => {
    let log = [];

    const dateFormat = (obj) => {
      if (!(obj instanceof Date)) throw(new Error('Input is not a instance of Date()'));

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const format = (obj) => {
        return days[obj.getDay()] + " " + obj.getDate() + " " + months[obj.getMonth()] + ", " + obj.getHours() + ":" + ("0"+obj.getMinutes()).slice(-2);
      }
      
      return format(obj);
    }

    const addMockupData = true;
    const mockupDate = new Date();
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }
    if (addMockupData) {
      let date = {
        date: dateFormat(mockupDate),
        events: [
          {
            key: "event" + getRandomInt(10000,99999),
            label: "Monthly coins added",
            type: "deposit",
            value: "9000",
          }
        ]
      }

      log.push(date);
    }

    return log;
  }

}

export default CognyCoins;