import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import LoadingDots from 'components/atoms/LoadingDots';
import { useCookies } from 'react-cookie';
import ArrowLink from 'components/molecules/ArrowLink';
import Button from 'components/atoms/Button';
import AddWarehouse from 'components/molecules/AddWarehouse';

function HomeView(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [cookies] = useCookies(["token", "project"]);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouses()
        .then(
          (result) => {
            if (!mounted) return;
            setIsLoaded(true);
            setWarehouses(result);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
  }, [cookies])

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">Welcome to <span className="TaskList__logo">Cogny</span></h1>
          {warehouses.length ?
            <Button type="link" href="/warehouse/new" className="TaskList__addTask" icon="plus">Create new warehouse</Button>
            : <p></p>}
        </div>
        <div>
          {
            !isLoaded ?
              <LoadingDots />
              :
              warehouses.length ?
                <div>
                  Select a warehouse below:
                  {warehouses.map(w => {
                    return (
                      <p key={w.name}>
                        <ArrowLink to={"/" + w.warehouse_id + "/dashboard"}>
                          {w.name}
                        </ArrowLink>
                      </p>
                    );
                  })}
                </div>
                :
                <div>
                  <p>Setup your first data warehouse to store your Google Analytics 4 data and more!</p>
                  <AddWarehouse />
                  <br />
                  <br />
                </div>
          }
        </div>
      </div>
    );
  }
}

export default HomeView;
