import { Link } from 'react-router-dom';
import './style.scss';

const menuItemsData = [
  {
    label: "Terms",
    href: "/terms",
  },
  {
    label: "Privacy",
    href: "/privacy",
  }
];

const scrollToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo (0,currentScroll - (currentScroll/5));
  }
};

const menuItems = menuItemsData.map((item, index) => {
  return <Link onClick={scrollToTop} className="Footer__item" to={item.href} key={index}>{item.label}</Link>
});

function Footer(props) {
  const currentYear = new Date().getFullYear();
  const years = currentYear !== 2021 ? "2021–" + currentYear : currentYear;
  return (
    <footer className={"Footer " + props.className }>

      &copy; {years} Cogny — {menuItems}
    </footer>
  );
}

Footer.defaultProps = {
  className: "",
};

export default Footer;