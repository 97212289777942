import "./style.scss";
import Button from "components/atoms/Button";
import { useCallback, useEffect, useRef, useState } from "react";

function ModalDialog(props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsOpen(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (isOpen) {
      ref.current?.showModal();
      document.body.classList.add("modal-open"); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const isClickInsideRectangle = (e, element) => {
    const r = element.getBoundingClientRect();

    return (
      e.clientX > r.left &&
      e.clientX < r.right &&
      e.clientY > r.top &&
      e.clientY < r.bottom
    );
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    ref.current && !isClickInsideRectangle(e, ref.current) && setIsOpen(false)
  }

  return (
    <>
      {props.label &&
        <Button
          className={props.className}
          onClick={handleClick}
          onMouseDown={(e) => e.stopPropagation()}
          title={props.title}
        >
          {props.label}
        </Button>
      }

      {!props.label && props.button &&
        <button
          className={props.className}
          onClick={handleClick}
          onMouseDown={(e) => e.stopPropagation()}
          title={props.title}
        >
          {props.button}
        </button>
      }

      {isOpen &&
        <dialog
          ref={ref}
          className={"ModalDialog " + props.modalClassName}
          onClick={handleModalClick}
        >
          {props.children}
          <div className="ModalDialog__toolbar">
            <Button onClick={() => setIsOpen(!isOpen)}>Close</Button>
          </div>
        </dialog>
      }
    </>
  )
};

ModalDialog.defaultProps = {
  modalClassName: "",
}

export default ModalDialog;