import './style.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import Tags from 'components/molecules/Tags';
import User from '../User';
import CognyUser from 'components/_classes/CognyUser';
import Icon from '../Icon';
import Priority from 'components/molecules/Priority';

function TaskCard(props) {
  const { task } = props;

  const TaskCardMeta = (props) => {
    const { requested, assigned, accepted } = props;
    const metaContent = {
      requested: {
        slug: 'requestedBy',
        label: 'Requested by',
        icon: 'request'
      },
      assigned: {
        slug: 'assignedTo',
        label: 'Assigned to',
        icon: 'user'
      },
      accepted: {
        slug: 'acceptedBy',
        label: 'Accepted by',
        icon: 'approve'
      }
    }
    const selected = requested ? metaContent.requested : assigned ? metaContent.assigned : accepted ? metaContent.accepted : false;

    if (!selected) return false;

    const user = new CognyUser(props.user).getUser();

    return (
      <div className={"TaskCard__metaBox TaskCard__" + selected.slug}>
        <div className="TaskCard__metaHeader">
          <Icon className="TaskCard__metaIcon" icon={selected.icon} />
          <h4 className="TaskCard__metaLabel">{selected.label}</h4>
        </div>
        <div className="TaskCard__metaUser">
          <User name={user.name} role={user.role} />
        </div>
      </div>
    );
  };

  return (
    <div className="TaskCard" key={task.getGid()}>
      <div className="TaskCard__main">
        { task.isPrioritizable() && <Priority task={task} /> }
        <Link className="TaskCard__link" to={"/task/" + task.getGid()}>
          <h3 className="TaskCard__title">{task.getTitle()}</h3>
        </Link>
        <Tags tags={task.getTags()} />
      </div>
      <div className="TaskCard__meta">
        <TaskCardMeta requested={task.getCreator()} />
        { task.isAssigned() ? <TaskCardMeta assigned={task.getAssignee()} /> : '' }
        { task.isAccepted() ? <TaskCardMeta accepted={task.getAcceptor()} /> : '' }
      </div>
    </div>
  );
}

export default TaskCard;