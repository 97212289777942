import ContactForm from 'components/organisms/ContactForm';
import './style.scss';

function Contact(props) {
  return (
    <div className="Contact">
      <ContactForm />
    </div>
  );
}

export default Contact;