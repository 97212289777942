import './style.scss';
import MenuItem from 'components/atoms/MenuItem';
import SignOutLink from 'components/atoms/SignOutLink';
import Icon from 'components/atoms/Icon';
import { useLocation } from 'react-router-dom';
import AccountSwitcher from 'components/atoms/AccountSwitcher';

function Menu(props) {
  const location = useLocation();

  // eslint-disable-next-line no-useless-escape
  const regex = /\/([a-fA-F0-9\-]+)\/.*/;
  const found = location.pathname.match(regex);

  let project = "";
  if (found && found[1]) {
    project = "/" + found[1];
  }

  const menuItemsData = [
    {
      label: "Home",
      href: "/home",
      icon: "home",
      //altHref: "/datasource",
    },
    {
      label: "Dashboard",
      href: project + "/dashboard",
      icon: "dashboard",
      //altHref: "/datasource",
    },
    {
      label: "Data sources",
      href: project + "/datasources",
      icon: "datasources",
      altHref: project + "/datasource",
    },
    {
      label: "Data copilot",
      href: project + "/terminal",
      icon: "terminal",
    },
    {
      label: "Data visualisation",
      href: project + "/datavis/start",
      icon: "datavis",
      //altHref: "/datasource",
    },
    {
      label: "Impact Analysis",
      href: project + "/impacts",
      icon: "impact",
      altHref: project + "/impact",
    },
    /*
    {
      label: "Media mix modelling",
      href: project+"/mmm",
      icon: "mmm",
      //altHref: "/datasource",
    },
    {
      label: "Attribution & LTV",
      href: project+"/ltv",
      icon: "attribution",
      //altHref: "/datasource",
    },
    */
    /*{
      label: "Persona modelling",
      href: project+"/personas",
      icon: "user",
      //altHref: "/datasource",
    },
    {
      label: "Data Exploration",
      href: project+"/data_exploration",
      icon: "user",
      //altHref: "/datasource",
    },
    */
    /*
     {
       label: "Audiences",
       href: project+"/audiences",
       icon: "user",
       //altHref: "/datasource",
     },*/
    {
      label: "Settings",
      href: project + "/settings",
      icon: "settings",
      //altHref: "/datasource",
    },
    /*
        {
          label: "My tasks",
          href: "/tasks",
          icon: "clipboardCheck",
          altHref: "/task",
        },
        {
          label: "Account",
          href: "/account",
          icon: "user",
        }
        */
  ];

  const menuItems = menuItemsData.map((item, index) => {
    const altHrefClass = item.altHref && location.pathname.indexOf(item.altHref) === 0 ? " MenuItem--active" : "";
    return <MenuItem className={"Menu__item" + altHrefClass} href={item.href} key={index}><Icon className="MenuItem__Icon Menu__icon" icon={item.icon} /><span className="MenuItem__label">{item.label}</span></MenuItem>
  });

  // drop from secondary for now
  //<CoinsLink coinsData={props.coinsData} className="Menu__CoinsLink" />

  return (
    <div className="Menu">
      <div className="Menu__primary">
        {menuItems}
      </div>
      <div className="Menu__secondary">
        <AccountSwitcher />
        <SignOutLink className="Menu__SignOutLink"><Icon icon="cardMoveFrom" className="MenuItem__Icon" /><span className="MenuItem__label">Sign Out</span></SignOutLink>
      </div>
    </div>
  );
}

export default Menu;
