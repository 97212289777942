import './style.scss';
import { Link, useLocation } from 'react-router-dom';
import LoadingDots from 'components/atoms/LoadingDots';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

function AccountSwitcher(props) {
  const [cookies, setCookie] = useCookies(["token", "project"]);
  const { search } = useLocation();
  const useQuery = new URLSearchParams(search);
  const [warehouse, setWarehouse] = useState(false);

  const [account, setAccount] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log(cookies);
  }, [cookies])

  if (error) {
    console.log(error);
    return <span className="AccountSwitcher__value">Error</span>;
  }

  if (!isLoaded) {
    return <LoadingDots className="AccountSwitcher__loading" alt="" />;
  }

  return (
    <div className={"AccountSwitcher " + props.className} onClick="">
      <span className="AccountSwitcher__label">Account</span>
      <span className="AccountSwitcher__value">{account}</span>
    </div>
  );
}

export default AccountSwitcher;
