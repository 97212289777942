import './style.scss';
import YouTube from 'react-youtube';

function MMMForm(props) {
  /*
  const options = [
    { value: 'growth_funnel', label: 'Growth Funnel', sample: '/images/datavis_funnel.png', description: 'The Growth Funnel report helps you understand the bottlenecks in your user journey.', ds_link: 'https://datastudio.google.com/reporting/0B_U5RNpwhcE6SF85TENURnc4UjA/preview' },
    { value: 'sales', label: 'Sales' },
    { value: 'ads', label: 'Ads' },
    { value: 'churn', label: 'Churn' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'products', label: 'Products' },
    { value: 'seo', label: 'SEO', sample: '/images/datavis_seo.png', description: 'The SEO report helps you keep an eye on your google ranking, important queries etc.', ds_link: 'https://datastudio.google.com/reporting/0B_U5RNpwhcE6QXg4SXFBVGUwMjg/preview' },
  ];
  */

  return (
    <>
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">Data Visualization</h1>
        </div>
        <p>Build powerful dashboard visualizations in Looker Studio.</p>
        <div>
          <br />
          <YouTube videoId="Lq0cT2u2rW8" />
          <br />
          <a className="Button" href="https://lookerstudio.google.com/" target="_BLANK" rel="noreferrer">Build your own in Looker studio</a>
        </div>
      </div>
    </>
  );
}

export default MMMForm;
