import './style.scss';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import TerminalEditor from 'components/molecules/TerminalEditor';

function Terminal(props) {
  const match = useRouteMatch();
  const terminalId = typeof match.params.id !== "undefined" ? match.params.id : null;

  return (
    <TerminalEditor id={terminalId} />
  )
}

export default Terminal;
