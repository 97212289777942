import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import AccessListing from 'components/atoms/AccessListing';
//import TaskStatus from 'components/atoms/TaskStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import Button from 'components/atoms/Button';
import { useCookies } from 'react-cookie';
import { useRouteMatch } from 'react-router-dom';

function SettingsUsers(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [access, setAccess] = useState([]);
  const [cookies] = useCookies(["token", "project"]);
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseAccess(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setAccess(result.access);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
            setIsLoaded(true);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="SettingsUsers">
        <div className="SettingsUsers__welcome">
          <h1 className="SettingsUsers__title">Users</h1>
          <Button type="link" href={"/" + warehouseId + "/access/new"} className="TaskList__addTask" icon="plus">Add new user</Button>
        </div>
        {
          !isLoaded ?
            <LoadingDots />
            :
            <div className="TaskList__box TasksBox--recurring">
              {
                access.length ?
                  access.map(ds => {
                    return (
                      <AccessListing key={ds.gid} task={ds} warehouseId={warehouseId} />
                    );
                  })
                  :
                  <p>No users?!</p>
              }
            </div>
        }
      </div>
    );

  }
}

export default SettingsUsers;
