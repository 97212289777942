import './style.scss';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Burger } from './burger.svg';
import { useState } from 'react';

const menuItemsData = [
  /*
  {
    label: "About us",
    href: "/about-us",
  },
  */
  {
    label: "Use cases",
    href: "/usecases",
  },
  /*
  {
    label: "Partners",
    href: "/partners",
  },
  {
    label: "Mission",
    href: "/mission",
  },
  */
  {
    label: "GA4",
    href: "/ga4",
  },
  {
    label: "Server-side tracking",
    href: "/sst",
  },
  /*
  {
    label: "Contact",
    href: "/contact",
  },
  */
];

const scrollToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }
};

function OutsideHeader(props) {
  const [navOpen, setNavOpen] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();

    setNavOpen(prev => !prev);
  }

  const handleLink = () => {
    if (window.innerWidth < 768) {
      setNavOpen(false);
    }
  }

  const apiHost = process.env.REACT_APP_API_BASE_URL;

  return (
    <header className="OutsideHeader">
      <div className="OutsideHeader__inner">
        <NavLink onClick={handleLink} className="OutsideHeader__logo" to="/"><span>Cogny</span></NavLink>

        <div className="OutsideHeader__nav">
          <button
            className={"OutsideHeader__toggle" + (navOpen ? " OutsideHeader__toggle--toggled" : "")}
            aria-expanded={navOpen ? "true" : "false"}
            aria-haspopup="true"
            aria-controls="outside-menu-overlay"
            onClick={handleToggle}
          >
            <Burger />
            <span>Toggle Menu</span>
          </button>

          <div className="OutsideHeader__links">
            {menuItemsData.map((item, index) => {
              return (
                <NavLink
                  className="OutsideHeader__link"
                  activeClassName="OutsideHeader__link--current"
                  to={item.href}
                  key={index}
                  onClick={scrollToTop}
                >
                  <span>{item.label}</span>
                </NavLink>
              );
            })}
          </div>

          <a className="OutsideHeader__signin" href={apiHost + "/api/v1/accounts/login"}><span>Sign in</span></a>
        </div>

        <div
          className={"OutsideHeader__overlay" + (navOpen ? " OutsideHeader__overlay--open" : "")}
          id="outside-menu-overlay"
        >
          <div className="OutsideHeader__overlay-backdrop"></div>
          <div className="OutsideHeader__overlay-nav">
            {menuItemsData.map((item, index) => {
              return (
                <NavLink
                  onClick={handleLink}
                  className="OutsideHeader__overlay-link"
                  to={item.href}
                  key={index}
                >
                  {item.label}
                </NavLink>
              );
            })}
          </div>
        </div>

      </div>
    </header>
  );
}

export default OutsideHeader;
