class CognyMeta {
  static statuses = [
    'Requested',
    'Estimated',
    'Approved',
    'In progress',
    'Answered',
    'Accepted'
  ];

  static atLeastStatus = (currentStatus = "", statusThreshold = "") => {
    const end = this.statuses.indexOf(currentStatus);
    const start = this.statuses.indexOf(statusThreshold);
    if (start === -1 || end === -1) {
      return false;
    }

    return start <= end;
  }

  static emptyUser = {
    gid: "",
    name: "",
    role: ""
  };

  static emptyTask = {
    gid: "",
    name: "",
    status: "",
    created_by: this.emptyUser,
    created_at: "",
    assignee: "",
    cost: "",
    recurring_cost: "",
    tags: [],
    priority: "",
    approved_by: this.emptyUser,
    approved_at: "",
    answered_by: this.emptyUser,
    answered_at: "",
    accepted_by: this.emptyUser,
    accepted_at: "",
    notes: "",
  }

  static taskDataParser = (apiData) => {
    if (!apiData) return false;
    let task = this.emptyTask;

    const parsedData = {
      gid: apiData.gid,
      name: apiData.name,
      status: ( apiData.status ? apiData.status : ( apiData.memberships ? apiData.memberships[0].section.name : "" ) ),
      created_by: false,
      created_at: apiData.created_at,
      assignee: apiData.assignee.gid,
      cost: false,
      recurring_cost: false,
      tags: apiData.tags,
      priority: ( apiData.custom_fields ? apiData.custom_fields[0].enum_value.name  : "" ),
      approved_by: false,
      approved_at: false,
      answered_by: false,
      answered_at: false,
      accepted_by: apiData.completed_by,
      accepted_at: apiData.completed_at,
      notes: apiData.notes,
    };

    for (var [key, value] of Object.entries(parsedData)) {
      task[key] = value ? value : "";
    }

    return task;
  }
}

export default CognyMeta;