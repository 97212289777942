import { Link } from 'react-router-dom';
import './style.scss';

const menuItemsData = [
  {
    label: "Terms",
    href: "/terms",
  },
  {
    label: "Privacy",
    href: "/privacy",
  },
  {
    label: "Cookies",
    href: "/cookies",
  }
];

const scrollToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }
};

function OutsideFooter(props) {
  return (
    <footer className="OutsideFooter">
      <div className="OutsideFooter__content">
        <div className="OutsideFooter__logo">Cogny</div>
        <div className="OutsideFooter__copyright">Copyright © Cogny AB</div>
      </div>
      <div className="OutsideFooter__nav">
        <div className="OutsideFooter__links">
          <a href="mailto:contact@cogny.com" className="OutsideFooter__link OutsideFooter__link--mailto">contact@cogny.com</a>
          <a href="https://meetings-eu1.hubspot.com/berner" className="OutsideFooter__link">Book a demo</a>
        </div>
        <div className="OutsideFooter__legal">
          {menuItemsData.map((item, index) => {
            return (
              <Link
                onClick={scrollToTop}
                className="OutsideFooter__link"
                to={item.href}
                key={index}
              >
                {item.label}
              </Link>
            )
          })}
        </div>
      </div>
    </footer>
  );
}

export default OutsideFooter;
