import './style.scss';
import Icon from 'components/atoms/Icon';
import { Link } from 'react-router-dom';

function Button(props) {
  const { icon, iconSide, type, href, className, onClick, label, children, disabled, variant, title, target, rel } = props;
  const text = label || children;

  if (type === 'htmllink') {
    return (
      <a
        href={href}
        className={"Button " + className + (iconSide ? " Button--icon-" + iconSide : "")}
        onClick={onClick}
        rel={rel}
        target={target}
      >
        {icon && iconSide === 'left' && (<Icon icon={icon} />)}
        <span className="Button__label">{text}</span>
        {icon && iconSide === 'right' && (<Icon icon={icon} />)}
      </a>
    );
  }
  if (type === 'link') {
    return (
      <Link
        to={href}
        className={"Button " + className + (iconSide ? " Button--icon-" + iconSide : "")}
        onClick={onClick}
      >
        {icon && iconSide === 'left' && (<Icon icon={icon} />)}
        <span className="Button__label">{text}</span>
        {icon && iconSide === 'right' && (<Icon icon={icon} />)}
      </Link>
    );
  } else {
    return (
      <button
        className={"Button " + className + (iconSide ? " Button--icon-" + iconSide : "") + (variant === "danger" ? " Button--danger" : "")}
        onClick={onClick}
        type={type}
        disabled={disabled}
        title={title}
      >
        {icon && iconSide === 'left' && (<Icon icon={icon} />)}
        <span className="Button__label">{text}</span>
        {icon && iconSide === 'right' && (<Icon icon={icon} />)}
      </button>
    );
  }
}

Button.defaultProps = {
  className: "",
  type: "button",
  href: "#",
  iconSide: "left",
  variant: "default",
  title: "",
};

export default Button;
