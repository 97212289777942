import './style.scss';

function Input(props) {
  return (
    <input 
      className={"Input " + props.className}
      key={props.id}
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value || props.children}
      disabled={props.disabled}
      {...props.inputAttributes}
    />
  );
}

Input.defaultProps = {
  type: "text",
  className: "",
};

export default Input;
