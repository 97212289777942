import Button from 'components/atoms/Button';
import DatasourceList from 'components/organisms/DatasourceList';
import { useRouteMatch } from 'react-router-dom';

function DatasourceSidebar() {
  const match = useRouteMatch();
  const [warehouseId] = match.params.warehouse_id;

  return (
    <div className="DatasourceSidebar">
      <Button type="link" href={"/" + warehouseId + "/datasource/new"} className="DatasourceSidebar__new" icon="plus">Add new data source</Button>
      <DatasourceList />
    </div>
  )
}

export default DatasourceSidebar;