import CognyUser from "./CognyUser";
import CognyStatus from "./CognyStatus";

/**
 * A class for handling all task stuff
 */
class CognyTask {
  /**
   * Constructor creates and stores simplified task object
   * @param {Object} jsonData - data from the /questions endpoint
   */
  constructor(jsonData) {
    this.task = this._taskDataParser(jsonData);
    this.rawTask = jsonData;
    this.statuses = new CognyStatus().getStatuses();
  }

  /**
   * An empty task object
   */
  emptyTask = {
    gid: 0,
    name: false,
    status: false,
    created_by: {},
    created_at: false,
    assigned_to: false,
    cost: false,
    recurring_cost: false,
    tags: [],
    priority: 1,
    priority_raw: false,
    approved_by: {},
    approved_at: false,
    answered_by: {},
    answered_at: false,
    accepted_by: {},
    accepted_at: false,
    notes: false,
    report: false,
  }

  /**
   * Return task object
   * @returns {Object} - Task object
   */
  getTask = () => {
    return this.task;
  }

  /**
   * Return raw JSON object from API
   * @returns {Object} - JSON data
   */
  getRawTask = () => {
    return this.rawTask;
  }

  /**
   * Return task gid
   * @returns {Number} - Task gid
   */
  getGid = () => {
    return this.task.gid;
  }

  /**
   * Return task title
   * @returns {String} - Task title
   */
  getTitle = () => {
    return this.task.name;
  }

  /**
   * Return task status
   * @returns {String} - Status label
   */
  getStatus = () => {
    return this.task.status;
  }

  /**
   * Return task notes
   * @returns {String} - Task notes
   */
  getNotes = () => {
    return this.task.notes;
  }

  /**
   * Return user who created the task
   * @returns {Object} - User object
   */
  getCreator = () => {
    return this.task.created_by;
  }

  /**
   * 
   * @param {String} type - Type of date 
   * @returns {String} - Date
   */
  getDate = (type) => {
    const types = [ 'created' ];
    if (types.indexOf(type) === -1) return false;

    let date = false;
    if (type === 'created' && this.task.created_at) {
      date = new Date(this.task.created_at);
    }

    return date;
  }

  /**
   * Return user assigned to the task
   * @returns {Object} - User object
   */
  getAssignee = () => {
    return this.task.assigned_to;
  }

  /**
   * Return user who accepted the task
   * @returns {Object} - User object
   */
  getAcceptor = () => {
    if (this.isAccepted()) {
      if (this.task.accepted_by) {
        return this.task.accepted_by;
      } else {
        return CognyUser.errorUser;
      }
    } else {
      return false;
    }
  }

  /**
   * Return task tags
   * @returns {Object} - Task tags
   */
  getTags = () => {
    return this.task.tags;
  }

  /**
   * Check if task has a recurring cost
   * @returns {Boolean}
   */
  hasRecurringCost = () => {
    return this.task.recurring_cost !== 0;
  }

  getRecurringCost = () => {
    return this.task.recurring_cost;
  }

  /**
   * Check if task is assigned to someone
   * @returns {Boolean}
   */
  isAssigned = () => {
    const cognyStatus = new CognyStatus(this.task.status);
    return cognyStatus.atLeastStatus('In progress');
  }

  /**
   * Check if task is accepted
   * @returns {Boolean}
   */
  isAccepted = () => {
    const cognyStatus = new CognyStatus(this.task.status);
    return cognyStatus.atLeastStatus('Accepted');
  }

  /**
   * Map api data to new task object format
   * @param {Object} apiData - data from the /questions endpoint
   * @returns {Object} - remapped data
   */
  _taskDataParser = (apiData) => {
    if (!apiData) throw(new Error('Missing API data'));
    let task = this.emptyTask;

    const priorityMapper = (str) => {
      if (typeof str !== "string") return 1;
      
      let prio = false;
      if (str.indexOf("Low") !== -1) {
        prio = 1;
      } else if (str.indexOf("Medium") !== -1) {
        prio = 2;
      } else if (str.indexOf("High") !== -1) {
        prio = 3;
      }

      return prio;
    }

    let mappedTask = {
      gid: ( apiData.gid ? parseInt(apiData.gid) : 0 ),
      name: ( apiData.name ? apiData.name : false ),
      status: ( apiData.status ? apiData.status : ( apiData.memberships ? apiData.memberships[0].section.name : false ) ),
      created_at: ( apiData.created_at ? apiData.created_at : false ),
      tags: apiData.tags,
      priority: ( apiData.custom_fields ? priorityMapper(apiData.custom_fields[0].enum_value.name) : 1 ),
      priority_raw: ( apiData.custom_fields ? apiData.custom_fields[0].enum_value.name : false ),
      notes: ( apiData.notes ? apiData.notes : false ),
      recurring_cost: 0,
    };

    const cognyStatus = new CognyStatus(mappedTask.status);

    // Only output accepted and assigned task meta based on status
    mappedTask.accepted_by = cognyStatus.atLeastStatus('Accepted') ? mappedTask.accepted_by : false;
    mappedTask.assigned_to = cognyStatus.atLeastStatus('In progress') ? mappedTask.assigned_to : false;

    // For testing recurring tab
    mappedTask.recurring_cost = mappedTask.gid === 1200484565594841 ? 500 : mappedTask.recurring_cost;

    // For testing tags
    const mockupTags = [
      {
        gid: "153768135",
        name: "Web",
      },
      {
        gid: "581387135",
        name: "Growth",
      },
      {
        gid: "851353183",
        name: "Churn",
      },
      {
        gid: "591385135",
        name: "Sales",
      },
      {
        gid: "513851835",
        name: "Data",
      },
      {
        gid: "139513681",
        name: "Analysis",
      },
      {
        gid: "913658134",
        name: "Ads",
      }
    ];

    const shuffledTags = [...mockupTags].sort(() => 0.5 - Math.random()); 
    const tagsMax = 5;
    const tagsMin = 1;
    const tagsN = Math.floor(Math.random() * (tagsMax - tagsMin + 1)) + tagsMin;
    mappedTask.tags = shuffledTags.slice(0, tagsN);
    // End of tag testing

    const mergedTask = Object.assign(task, mappedTask);

    return mergedTask;
  }

  /**
   * Check if task is in the todo section in the ongoing tasklist
   * @returns {Boolean}
   */
  isTodo = () => {
    const cognyStatus = new CognyStatus(this.getStatus());
    return cognyStatus.isTodo();
  }

  /**
   * Check if task is on the ongoing tab in tasklist
   * @returns {Boolean}
   */
  isOngoing = () => {
    const cognyStatus = new CognyStatus(this.getStatus());
    return cognyStatus.isOngoing();
  }

  /**
   * Check if task is in the waiting section in the ongoing tasklist
   * @returns {Boolean}
   */
  isWaiting = () => {
    const cognyStatus = new CognyStatus(this.getStatus());
    return cognyStatus.isWaiting();
  }

  /**
   * Check if task is the final task stage
   * @returns {Boolean}
   */
  isFinal = () => {
    const cognyStatus = new CognyStatus(this.getStatus());
    return cognyStatus.isFinal();
  }
  
  /**
   * Check if task is a given status
   * @param {String} status - Status label 
   * @returns {Boolean}
   */
  isStatus = (status) => {
    if (Number.isInteger(status)) {
      return this.getStatus() === this.statuses[status];
    } else {
      return this.getStatus() === status;
    }
  }

  /**
   * Check if task has notification and return notification object
   * @returns {Object} - Notification object
   */
  getNotification = () => {
    let notification;
    if (this.isStatus("Estimated")) {
      notification = {
        message: "This request has an estimated cost that needs your approval",
        id: "approve-estimate",
        action: "Approve cost"
      };
    } else if (this.isStatus("Answered")) {
      notification = {
        message: "This request has an answer and needs to be accepted",
        id: "accept-answer",
        action: "Accept answer"
      };
    } else {
      notification = {
        message: "",
        id: "",
        action: "",
      }
    }

    return notification;
  }

  /**
   * Check if task is allowed to change priority
   * @returns {Boolean}
   */
  isPrioritizable = () => {
    const prioCutoff = [ this.statuses[0], this.statuses[1] ];
    return prioCutoff.indexOf(this.getStatus()) !== -1;
  }

  /**
   * Get task priority
   * @returns {Number} - 1 (Low), 2 (Medium) or 3 (High)
   */
  getPriority = () => {
    return this.task.priority;
  }

  /**
   * Get activity log for task
   * @returns {Object} - Activity log
   */
  getActivityLog = () => {
    let log = [];

    const dateFormat = (obj) => {
      if (!(obj instanceof Date)) throw(new Error('Input is not a instance of Date()'));

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const format = (obj) => {
        return days[obj.getDay()] + " " + obj.getDate() + " " + months[obj.getMonth()] + ", " + obj.getHours() + ":" + ("0"+obj.getMinutes()).slice(-2);
      }
      
      return format(obj);
    }

    const created = this.getDate('created');
    if (created) {
      let date = {
        date: dateFormat(created),
        events: [
          {
            key: created.valueOf()+"request1254135134",
            icon: "request",
            label: "Requested by",
            content: {
              gid: "1254135134",
              name: "Log user",
              role: "User role",
            }
          }
        ]
      }

      log.push(date);
    }

    return log;
  }
  
  /**
   * Check if task has a report
   * @returns {Boolean}
   */
  hasReport = () => {
    return !!this.task.report;
  }

  /**
   * Get task report
   * @returns {String} - Task report HTML
   */
  getReport = () => {
    return this.hasReport() ? this.task.report : false;
  }


}

export default CognyTask;