import Footer from 'components/molecules/Footer';
import './style.scss';
import PageSidebar from 'components/organisms/PageSidebar';
import Sidebar from 'components/organisms/Sidebar';

function Main(props) {

  return (
    <>
      {!props.noSidebar && <Sidebar />}

      {props.pageSidebar && <PageSidebar children={props.pageSidebar} />}

      <main
        className={
          "Main"
          + (props.noSidebar ? " Main--withoutSidebar" : "")
          + (!props.noSidebar ? " Main--withSidebar" : "")
          + (props.pageSidebar ? " Main--withPageSidebar" : "")
        }
      >
        {props.children}
        <Footer />
      </main>
    </>
  );
}

export default Main;
