import './style.scss';
import React from 'react';
import Title from 'components/atoms/Title';
import LoadingDots from 'components/atoms/LoadingDots';
import SectionHeader from 'components/molecules/SectionHeader';
import Header from 'components/molecules/Header';
import ArrowLink from 'components/molecules/ArrowLink';
import CognyCoins from 'components/_classes/CognyCoins';
import camelcase from 'camelcase';

function CurrentCoins(props) {
  const {
    coins,
    coinsError,
    coinsIsLoaded
  } = props.coinsData;

  const CurrencyValue = (props) => {
    if (coinsError) {
      console.log(coinsError);
      return "Error";
    } else if (!coinsIsLoaded) {
      return <LoadingDots className="CurrentCoins__loading" alt="" imgAttributes={{height:22}} />;
    } else {
      return <span className="CurrentCoins__value">{coins}</span>;
    }
  };

  const Blurbs = () => {
    return (
      <div className="CurrentCoins__blurbs">

        <div className="Blurb">
          <h2 className="Blurb__title">Current coins</h2>
          <div className="Blurb__value"><CurrencyValue /></div>
          <div className="Blurb__unit"></div>
          <div className="Blurb__link"><ArrowLink to="/coins/add">Add more coins</ArrowLink></div>
        </div>
        
        <div className="Blurb">
          <h2 className="Blurb__title">Monthly coins</h2>
          <div className="Blurb__value">9000</div>
          <div className="Blurb__unit">coins/month</div>
          <div className="Blurb__link"><ArrowLink to="/contact">Change plan</ArrowLink></div>
        </div>
        
        <div className="Blurb">
          <h2 className="Blurb__title">Recurring task cost</h2>
          <div className="Blurb__value">100</div>
          <div className="Blurb__unit">coins/month</div>
          <div className="Blurb__link"><ArrowLink to="/tasks/recurring">Show recurring</ArrowLink></div>
        </div>
        
      </div>
    );
  }

  const CoinHistory = () => {
    const coins = new CognyCoins();
    return (
      <div className="CoinHistory">
        <h2 className="CoinHistory__title">Coin history</h2>

        <ul className="CoinHistory__list">
          {
            coins.getCoinHistory().length ?
              coins.getCoinHistory().map((date) => {
                const dateKey = camelcase('helloHello');
              
                return (
                  <li key={dateKey} className="CoinHistory__item">
                    <h2 className="CoinHistory__date"><span>{date.date}</span></h2>
                    <ul className="CoinHistory__events">
                      {
                        date.events.map((event) => {
                          return (
                            <li key={event.key} id={event.key} className="CoinHistory__event">
                              <div className="CoinHistory__eventMeta">
                                <h3 className="CoinHistory__eventLabel"><span>{event.label}</span></h3>
                              </div>
                              <div className="CoinHistory__eventContent">
                                {(event.type === "withdrawal" ? "–" : "") + event.value}
                              </div>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </li>
                )
              })
            :
              <li className="CoinHistory__item CoinHistory__item--emptyMessage">You have no history</li>
          }
        </ul>
      </div>
    );
  }

  return (
    <>
      <Header></Header>

      <div className="CurrentCoins">
        <SectionHeader className="AccountForm__header">
          <Title>Coin balance</Title>
        </SectionHeader>

        <Blurbs />

        <CoinHistory />
      </div>
    </>
  );
}

export default CurrentCoins;