/**
 * A class for handling all user stuff
 */
class CognyUser {
  constructor(gid) {
    this.user = Number.isInteger(gid) ? this._userFromGid(gid) : false;
  }

  /**
   * An empty user object
   */
  static emptyUser = {
    gid: "",
    name: "",
    role: "",
  };

  static errorUser = {
    gid: "",
    name: "Should have data",
    role: "But doesn't",
  }

  /**
   * Generate user object from gid. Will hopefully return real data one day.
   * @param {number} gid - User gid
   * @returns {Object} - Fake data for now
   */
  _userFromGid(gid) {
    if (!gid) return false;

    const userObject = {
      gid: gid, 
      name: "Some name",
      role: "Some role"
    }

    return userObject;
  }

  getUser() {
    return this.user;
  }
}

export default CognyUser;