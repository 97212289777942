import './style.scss';
import React from 'react';

function Tab(props) {

  const handleClick = (e) => {
    e.preventDefault();

    const { slug, onClick } = props;
    onClick(slug);
  }

  const { activeTab, label, meta, icon, slug, basepath } = props;

  let classNamePrefix="Tab";
  let className=classNamePrefix;

  if (activeTab === slug) {
    className += " Tab--active";
  }

  const Meta = (props) => {
    return (
      <span className={props.parentClass + '__Meta'}>{props.children}</span>
    );
  };

  return (
    <li className={className}>
      <a href={basepath + slug} className={classNamePrefix + "__link"} onClick={handleClick}>
        {icon ? icon : ''}<span className="Tab__label">{label}</span> {meta ? <Meta parentClass={classNamePrefix}>{meta}</Meta> : ''}
      </a>
    </li>
  );
}

export default Tab;