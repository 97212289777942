import OutsideBox from 'outside/components/OutsideBox';
import './style.scss';

function Cookies() {
  return (
    <OutsideBox classBase="Cookies">
      <div className="Cookies">
        <h1>Cogny Cookie policy</h1>
        <p>Effective date: February 13, 2023</p>

        <p>This cookie policy (“Policy”) explains how Cogny AB (“Cogny”, “we” or “us”) uses cookies and similar technologies on our website, www.cogny.com (“Website”).</p>

        <h2>WHAT ARE COOKIES?</h2>
        <p>Cookies are small text files that are stored on your device by a website. They are used to remember your preferences, to collect information about your use of the Website, and to provide a more personalized experience.</p>
        <h2>TYPES OF COOKIES WE USE</h2>
        We use the following types of cookies on the Website:
        <ul>
          <li>Essential cookies: These cookies are necessary for the Website to function properly and provide the Services you have requested. Without these cookies, we would not be able to provide certain features, such as remembering your preferences and keeping you logged in.</li>
          <li>Analytical cookies: These cookies collect information about how you use the Website, such as which pages you visit and which links you click. We use this information to improve the Website and to provide more relevant content and advertising.</li>
          <li>Marketing cookies: These cookies collect information about your interests and your use of the Website and other websites, in order to show you more relevant advertising.</li>
        </ul>
        <h2>YOUR CHOICES</h2>
        Most web browsers allow you to control cookies through the browser settings. You can set your browser to refuse all cookies, to accept only specific types of cookies, or to delete cookies after each session.
        Please note that if you reject or delete cookies, some features of the Website may not be available or may not function properly. Additionally, by deleting cookies, you may need to enter your preferences each time you visit the Website.
        <h2>UPDATES TO THIS POLICY</h2>
        We may change this Policy from time to time. Changes to the Policy will be posted on our Website, and you accept the revised Policy by continuing to use the Website after the changes have been published.
        <h2>YOUR CHOICES</h2>
        If you have any questions or concerns about this Policy or our use of cookies, please contact us at contact [at] cogny.com
      </div>
    </OutsideBox>
  );
}

export default Cookies;
