import './style.scss';

function OutsideBox(props) {
  return (
    <div className={props.classBase + "__outer OutsideBox" + (props.nobg ? " OutsideBox--nobg" : "")}>
      {props.children}
    </div>
  );
}

export default OutsideBox;
