import './style.scss';
import React, { useState, useEffect, useRef } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';
import TerminalVisualization from 'components/molecules/TerminalVisualization';
import ReactGA from "react-ga4";
import { components } from "react-select";
import Code from 'components/molecules/Code';
import PageHeader from 'components/organisms/PageHeader';
import ModalDialog from 'components/molecules/ModalDialog';

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const SubmitButton = (props) => {
  const { isLoaded, error, task } = props;

  return (
    <>
      <Button type="submit" className="TerminalEditor__submit" icon="arrowRight" iconSide="right" disabled={task.originalPrompt === task.prompt}>{task && task.id ? "Continue exploring" : "Explore"}</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TerminalEditor" successMessage="" />
    </>
  )
}

function TerminalEditor(props) {
  const history = useHistory();

  const [exploration, setExploration] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const explorationRef = useRef(exploration);

  const [tables, setTables] = useState([]);
  const [tablesError, setTablesError] = useState(null)
  const [tablesIsLoaded, setTablesIsLoaded] = useState(false);

  const [metric, setMetric] = useState({});
  const [metricError, setMetricError] = useState(null);
  const [metricIsLoaded, setMetricIsLoaded] = useState(false);

  const [saveError, setSaveError] = useState(null);
  const [saveIsLoaded, setSaveIsLoaded] = useState(false);

  const [deleteError, setDeleteError] = useState(null);
  const [setDeleteLoaded, setDeleteIsLoaded] = useState(false);

  const match = useRouteMatch();
  const [cookies] = useCookies(["token"]);
  const warehouseId = match.params.warehouse_id;
  const terminalId = props.id;

  useEffect(() => {
    explorationRef.current = exploration;
  }, [exploration]);

  useEffect(() => {
    let mounted = true;
    let polling;
    if (cookies.token) {
      if (terminalId) {
        const getXFormData = () => {
          console.log(explorationRef.current);
          const api = new CognyAPI(cookies.token);
          api.getXFormDetail(warehouseId, terminalId)
            .then(
              (result) => {
                if (!mounted) return;
                result.originalPrompt = result.prompt;
                setExploration(result);
                setIsLoaded(true);
              },
              (error) => {
                if (!mounted) return;
                setIsLoaded(true);
                setError(error);
              }
            );
        }

        getXFormData();
        //setting up polling
        polling = setInterval(() => {
          getXFormData();

          if (explorationRef.current.query) {
            //found data, aborting polling
            clearInterval(polling);
          }
        }, 10000);

      } else {
        setExploration({});
        setIsLoaded(true);
      }
    }
    return () => {
      mounted = false;
      clearInterval(polling);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, terminalId]);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getDatasourceDetails(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            const updatedTables = formatDatasourceData(result);

            let groupedSets = [];

            updatedTables.forEach(source => {
              if (!groupedSets.filter(dataset => dataset.label === source.dataset).length) {
                groupedSets.push({ label: source.dataset, options: [] });
              }

              let datasetFilter = groupedSets.filter(dataset => dataset.label === source.dataset);
              if (typeof datasetFilter[0] !== 'undefined') {
                datasetFilter[0].options.push(source);
              }
            })

            setTablesIsLoaded(true);
            setTables(groupedSets);
          },
          (error) => {
            if (!mounted) return;
            setTablesIsLoaded(true);
            setTablesError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  const formatDatasourceData = (datasources) => {
    if (!datasources) return null;

    return datasources.map(source => {
      //var label = source.id;
      // eslint-disable-next-line no-useless-escape
      //label = label.replace(/^([^\.]+\.)/, "");

      let parts = source.id.split('.');

      let shortname = parts[2];
      let dataset = parts[1];

      return {
        dataset: dataset,
        value: source.id,
        label: shortname,
        shortname: shortname,
        id: source.id,
        schema: source.schema
      };
    });
  }

  /* becomes update xfrom */
  const handleSave = (e) => {
    e.preventDefault();

    var xform_type = 'xform';
    var submitter_classes = Array.from(e.nativeEvent.submitter.classList);
    if (submitter_classes.includes('TerminalEditor__submit_metric')) {
      xform_type = 'metric';
    }

    var saveMetric = {
      name: metric.name,
      type: xform_type,
    };

    setIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.updateXForm(warehouseId, terminalId, saveMetric)
        .then(
          (taskResult) => {
            setSaveIsLoaded(true);
            console.log("result:");
            console.log(taskResult);
          },
          (error) => {
            setSaveIsLoaded(true);
            setSaveError(error);
          }
        )
    }
  }

  const handleDelete = (e) => {
    e.preventDefault();

    setDeleteIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);

      api.deleteXForm(warehouseId, exploration.id)
        .then(
          (taskResult) => {
            history.push("/" + warehouseId + "/terminal/");
            setDeleteIsLoaded(true);
          },
          (error) => {
            setDeleteIsLoaded(true);
            console.log(error);
            setDeleteError(error);
          }
        )
    }
  }


  /* create xform  */
  const handleSubmit = (e) => {
    e.preventDefault();

    var queryAdvise = {
      type: "custom",
      prompt: exploration.prompt,
      datasources: exploration.datasources,
    };
    ReactGA.event({
      category: "UserActions",
      action: "DataCopilotSession",
      label: cookies.token,
    });

    setIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      setError(""); // reset error
      setTables([]);
      api.addXForm(warehouseId, queryAdvise)
        .then(
          (taskResult) => {
            console.log(taskResult);
            history.push("/" + warehouseId + "/terminal/" + taskResult.id);
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  const handleHintChange = (e) => {
    var examples = {
      "metric": "daily users",
      "xform": "",
      "ga4_funnel": "funnel with steps:\nevent_name = 'page_view'\nevent_name = 'add_to_cart'\nevent_name = 'purchase'\n",
    };
    /* TODO:
     * "merge marketing stuff + currency calc" - campaigns, impressions, clicks, cost, revenue etc from google_ads, facebook, linkedin
     * 
     *  "merge targets + cost + events" - 
     * 
     * sankey style event mapping form GA4 
     *   source: event x, target: event y, date, count
     *   source: event y, target: event z, date, count
     *
     *    // TODO figure out exact format for sankey
     * 
     *  "merge ga4 vs ga3" - 
     */

    if (exploration.prompt === undefined || exploration.prompt === "" || [examples.metric, examples.xform, examples.ga4_funnel].indexOf(exploration.prompt) !== -1) {
      setExploration(currentState => ({
        ...currentState,
        prompt: examples[e.value]
      }));
    }
  };

  const Validation = (props) => {
    if (terminalId === 'name') {
      var nameRe = /^[a-z][a-z0-9_]*$/;
      //if ((typeof metric.name === "undefined" || !metric.name.length)) {
      if (typeof metric.name !== "undefined" && !metric.name.length) {
        return <div className="TerminalEditor__validation TerminalEditor__validation--invalid">You need to enter a name.</div>;
      } else if (typeof metric.name !== "undefined" && !metric.name.match(nameRe)) {
        return <div className="TerminalEditor__validation TerminalEditor__validation--invalid">The name can only contain lower case letters, underscores and numbers.</div>;
      }
    }
    return "";
  };

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        {children}
      </components.Control>
    );
  };

  const MultiValueLabel = (props) => {
    return (
      <>
        <ModalDialog className="Datasource" title="Show query" button={
          <components.MultiValueLabel {...props}>
            <span className="Datasource__dataset">{props?.data?.dataset}</span>
            <span className="Datasource__label">{props.children}</span>
          </components.MultiValueLabel>
        }>
          <Code label="query" language="SQL">{props?.data?.schema}</Code>
        </ModalDialog>
      </>
    )
  };

  return (
    <div className="TerminalEditor">
      {!exploration.id && isLoaded &&
        <PageHeader pretitle="Data copilot" title="Start a new exploration" />
      }

      {exploration.id && exploration.name && isLoaded &&
        <PageHeader pretitle="Data copilot" title={exploration.name} />
      }

      {exploration.id && !exploration.name && isLoaded &&
        <PageHeader pretitle="Data copilot" title="Ongoing exploration" />
      }

      <Form className="TerminalEditor__form" onSubmit={handleSubmit}>
        {!exploration.id && isLoaded &&
          <FieldRow>
            <Field
              type="select"
              id="TerminalEditor__type"
              className="TerminalEditor__tags"
              name="Transform type"
              options={[
                { "value": "metric", "label": "Metric" },
                { "value": "ga4_funnel", "label": "GA4 Funnel" },
                { "value": "xform", "label": "Custom transformation" },
              ]}
              onChange={handleHintChange}
              value={exploration.type ? exploration.type : null}
              classNamePrefix="Select"
              placeholder="Select type of transform..."
            />
          </FieldRow>
        }

        <FieldRow>
          {tablesIsLoaded && !tablesError ?
            <Field
              type="multiselect"
              id="TerminalEditor__tags"
              className="TerminalEditor__tags"
              name="Data sources"
              options={tables}
              onChange={
                (value) => {
                  setExploration(currentState => ({
                    ...currentState,
                    datasources: value
                  }));
                }
              }
              value={formatDatasourceData(exploration.datasources)}
              classNamePrefix="Select"
              placeholder="Select data source..."
              components={{ Control, MultiValueLabel }}
            />
            :
            <LoadingDots />
          }
        </FieldRow>

        <FieldRow>
          <Field
            type="textarea"
            id="TerminalEditor__prompt"
            className="TerminalEditor__prompt"
            name="Prompt"
            value={exploration.prompt ? exploration.prompt : ''}
            onChange={(e) => {
              setExploration(currentState => ({
                ...currentState,
                prompt: e.target.value
              }));
            }}
            placeholder="Enter a prompt..."
          />
        </FieldRow>

        <SubmitButton isLoaded={isLoaded} error={error} task={exploration} />

      </Form>

      {exploration.id &&
        <div className="TerminalEditor__exploration">
          <div className="TerminalEditor__explorationHeader">
            <h2 className="TerminalEditor__explorationTitle">

              {exploration.id && !exploration.query &&
                <>
                  <div>
                    Data copilot is <strong>getting your data</strong> and will be done in a moment.
                  </div>
                  <LoadingDots />
                </>
              }

              {exploration.id && exploration.query && !exploration.name &&
                <div>
                  Your data is <strong>done</strong> and ready to be saved. Or edit your prompt to continue exploring.
                </div>
              }

              {exploration.id && exploration.query && exploration.name &&
                <div>
                  This is a <strong>saved</strong> exploration. You can edit your prompt above if you want to continue exploring.
                </div>
              }
            </h2>
          </div>

          {exploration.error ?
            <p>Error: {exploration.error}</p>
            : ""
          }


          {exploration.query &&
            <Form className="TerminalEditor__toolbarForm" onSubmit={handleSave}>
              <FieldRow>
                <Field
                  type="text"
                  id="TerminalEditor__metric_name"
                  className="TerminalEditor__metric_name"
                  name="Name"
                  value={exploration.name ? exploration.name : ''}
                  onChange={(e) => {
                    if (e.target && e.target.id === "TerminalEditor__metric_name") {
                      setMetric(currentState => ({
                        ...currentState,
                        name: e.target.value
                      }));
                    }
                  }}
                  validation={<Validation id="name" />}
                  placeholder="Enter a name for your transformation / metric"
                />
              </FieldRow>
              {/*
              <pre>{JSON.stringify(exploration)}</pre>
            */}
              <div className="TerminalEditor__toolbarButtons">
                {exploration.type === 'custom' &&
                  <>
                    {exploration.is_timeseries &&
                      <Button
                        type="submit"
                        className="TerminalEditor__submit_metric"
                        id="save_metric"
                      >
                        {isLoaded ? "Save as metric" : <LoadingDots />}
                      </Button>
                    }
                    <Button
                      type="submit"
                      className="TerminalEditor__submit_xform"
                      id="save_transform"
                    >
                      {isLoaded ? "Save as custom transformation" : <LoadingDots />}
                    </Button>
                  </>
                }

                {exploration.query &&
                  <ModalDialog className="TerminalEditor__modalToggle" title="Show query" label="Show query">
                    <Code label="query" language="SQL">{exploration.query}</Code>
                  </ModalDialog>
                }

                {exploration.id ?
                  <Button onClick={handleDelete} className="TerminalEditor__delete" variant="danger" title="Delete">Delete</Button>
                  : ""}
              </div>

            </Form>
          }

          {exploration.query && isLoaded &&
            <TerminalVisualization help={exploration.help} exploration={terminalId} />
          }
        </div>
      }

      {error ? <div>{error.message}</div> : ""}

    </div>
  )
}

export default TerminalEditor;
