import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import ReactGA from "react-ga4";

import App from 'App';
import Outside from 'outside';

function AuthRouter() {
  const [cookies, setCookie] = useCookies(["token","project"]);
  const { search } = useLocation();
  const useQuery = new URLSearchParams(search);

  ReactGA.initialize("G-XT72KY3FLP");

  useEffect(() => {
    if (
      useQuery.get("auth") !== null
    ) {
      const token = useQuery.get("auth");
      setCookie("token", token, {path:"/", sameSite: "strict"});
      if (
        useQuery.get("f") !== null
      ) {
        const first = useQuery.get("f");
        if (first === "true") {
        	ReactGA.event({
        	  category: "UserActions",
        	  action: "FirstLogin",
        	  label: token,
        	});
        } else {
        	ReactGA.event({
        	  category: "UserActions",
        	  action: "Login",
        	  label: token,
        	});
	}
      }
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!cookies.token || cookies.token === null) {
    return (
      <Outside />
    )
  }

  return (
    <App />
  )
}

export default AuthRouter;
