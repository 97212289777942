import './style.scss';
import Button from 'components/atoms/Button';
import CognyAPI from 'components/_classes/CognyAPI';
import SubmitStatus from 'components/molecules/SubmitStatus';
import { useRouteMatch } from 'react-router-dom';
import React, { useState } from 'react';
import Form from 'components/atoms/Form';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="mmm-get-started">Get started with Audiences</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function AudiencesForm(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [cookies] = useCookies(["token", "project"]);
  const [message, setMessage] = useState("");
  const match = useRouteMatch();
  const [warehouseId] = useState(match.params.warehouse_id);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoaded(false);

    const newFeature = {
      feature: "audiences",
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      ReactGA.event({
        category: "UserActions",
        action: "AddFeatureAudiences",
        label: cookies.token,
      });
      api.addWarehouseFeature(warehouseId, newFeature)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setMessage("You have been added to the Audiences waitlist, we'll be in touch!");
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }
  }
  return (
    <>
      <div className="TaskList">
        <div className="TaskList__welcome">
          <h1 className="TaskList__title">Audiences <span className="TaskList__logo">(beta)</span></h1>
        </div>

        <img src="/images/audience_sample.png" width="300" align="right" style={{ padding: 20 + 'px' }} alt="ML Audience improving CTR for ads" />
        <p>There is a hugh shift happening in the online ad industry, moving towards e-privacy and away from third-party cookies.</p>
        <p>The way to stay relevant is to leverage your 1st party data, both in it's raw form with better and faster integration and using machine learning to create predictive audiences. We'll help you with both</p>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          {message === "" ?
            <Form className="TaskEditor__form" onSubmit={handleSubmit}>
              <SubmitButton isLoaded={isLoaded} error={error} />
            </Form>
            : <p>{message}</p>}
        </div>
      </div>
    </>
  );
}

export default AudiencesForm;
