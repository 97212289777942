import './style.scss';
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import TaskCard from 'components/atoms/TaskCard';
import TaskStatus from 'components/atoms/TaskStatus';
import Tabs from 'components/molecules/Tabs';
import camelcase from 'camelcase';
import LoadingDots from 'components/atoms/LoadingDots';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import CognyStatus from 'components/_classes/CognyStatus';
import CognyTask from 'components/_classes/CognyTask';
import Title from 'components/atoms/Title';
import { useCookies } from 'react-cookie';

/* 
apiData = [
  {
    "gid": "1200844750643442",
    "status": "Requests",
    "name": "Special audit tools",
    "notes": ""
  }
];
*/

function TaskList(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tasks, setTasks] = useState([]);
  const match = useRouteMatch();
  const [cookies] = useCookies(["token","project"]);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      let project = cookies.project;
      api.getTasks(project)
        .then(
          (result) => {
            if (!mounted) return;
            let parsedTasks = [];

            result.forEach((task) => {
              const parsedTask = new CognyTask(task);
              Object.freeze(parsedTask);
              parsedTasks.push(parsedTask);
            });

            setIsLoaded(true);
            setTasks(parsedTasks);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
  }, [cookies])
  
  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    let path = typeof match.params.tabId !== "undefined" ? match.params.tabId : false ;

    const todoTasks = () => {
      return tasks.filter(task => task.isTodo());
    }

    const ongoingTasks = () => {
      return tasks.filter(task => task.isOngoing());
    }

    const finishedTasks = () => {
      return tasks.filter(task => task.isFinal());
    }

    const recurringTasks = () => {
      return tasks.filter(task => task.hasRecurringCost());
    }

    const statuses = new CognyStatus().getStatuses();



    return (
        <div className="TaskList">

          <div className="TaskList__welcome">
            <h1 className="TaskList__title">Welcome to <span className="TaskList__logo">Cogny</span></h1>
            <Button type="link" href="/task/new" className="TaskList__addTask" icon="plus">Add new request</Button>
          </div>
          
          {
            !isLoaded ? 
              <LoadingDots />
            :
              <Tabs active={path} basepath="/tasks/">
                <div
                  icon={<Icon icon="clipboardCheck" className="Tab__Icon" />}
                  label="Ongoing"
                  slug="ongoing"
                  meta={ongoingTasks() ? ongoingTasks().length : ""}
                >
                  <div className="TaskList__todo">
                    <Title className="TaskList__todoTitle">
                      <span className="TaskList__todoTitleCount">{todoTasks().length} request{todoTasks().length === 1 ? '' : 's'}</span> waiting for response
                    </Title>

                    {
                      statuses
                        .filter(status => new CognyStatus(status).isTodo())
                        .map(status => {
                          const statusClass = camelcase(status);
                          const count = tasks.filter(task => task.isStatus(status)).length;

                          return (
                            <TaskStatus status={statusClass} key={statusClass} title={status} count={count}>
                              {
                                tasks
                                  .filter(task => task.isStatus(status))
                                  .map(task => {
                                    return (
                                      <TaskCard key={task.getGid()} task={task} />
                                    );
                                  })
                              }
                            </TaskStatus>
                          )
                        })
                    }
                  </div>
                  
                  <div className="TaskList__box">
                    {
                      statuses
                        .filter(status => new CognyStatus(status).isWaiting())
                        .map(status => {
                          const statusClass = camelcase(status);
                          const count = tasks.filter(task => task.isStatus(status)).length;

                          return (
                            <TaskStatus status={statusClass} key={statusClass} title={status} count={count}>
                              {
                                tasks
                                  .filter(task => task.isStatus(status))
                                  .map(task => {
                                    return (
                                      <TaskCard key={task.getGid()} task={task} />
                                    );
                                  })
                              }
                            </TaskStatus>
                          )
                        })
                    }
                  </div>

                </div>

                <div icon={<Icon icon="approve" className="Tab__Icon" />} label="Accepted" slug="accepted">
                  <div className="TaskList__box TasksBox--accepted">
                    {
                      finishedTasks().length ?
                        finishedTasks().map(task => {
                          return (
                            <TaskCard key={task.getGid()} task={task} />
                          );
                        })
                      :
                        <p>You have no accepted tasks</p>
                    }
                  </div>
                </div>

                <div icon={<Icon icon="coins" className="Tab__Icon" />} label="Recurring costs" slug="recurring">
                  <div className="TaskList__box TasksBox--recurring">
                    {
                      recurringTasks().length ?
                        recurringTasks().map(task => {
                          return (
                            <TaskCard key={task.getGid()} task={task} />
                          );
                        })
                      :
                        <p>You have no recurring costs</p>
                    }
                  </div>
                </div>
              </Tabs>
          }

        </div>
    );
  }
}

export default TaskList;
