import './style.scss';

function Title(props) {
  const titleLevels = [1,2,3,4,5,6];

  let Tag;
  if (titleLevels.indexOf(parseInt(props.level)) !== -1) {
    Tag = "h" + props.level;
  } else {
    Tag = "h1";
  }

  return (
    <Tag className={"Title " + props.className }>{props.children}</Tag>
  );
}

Title.defaultProps = {
  className: "",
};

export default Title;