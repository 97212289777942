import './style.scss';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ReactGA from "react-ga4";

import Terms from 'outside/pages/Terms';
import Privacy from 'outside/pages/Privacy';
import Cookies from 'outside/pages/Cookies';
import GA4 from 'outside/pages/GA4';
import UseCases from 'outside/pages/UseCases';
import Mission from 'outside/pages/Mission';
import Frontpage from 'outside/pages/Frontpage';

import OutsideHeader from './components/OutsideHeader';
import OutsideFooter from 'outside/components/OutsideFooter';
import OutsideContent from './components/OutsideContent';
import NotFound from './pages/NotFound';
import SST from 'outside/pages/SST';

function Outside() {

  ReactGA.initialize("G-XT72KY3FLP");

  const defaultTitle = "Cogny - Unlock faster time to insight and action";

  const apiHost = process.env.REACT_APP_API_BASE_URL;

  function GoToLogin() {
    window.location.replace(apiHost + "/api/v1/accounts/login");
  }

  return (
    <div className="Outside">
      <OutsideHeader />

      <Switch>

        <Route path="/login">
          <GoToLogin />
        </Route>

        <Route path="/terms">
          <Helmet>
            <title>Terms - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage>
            <Terms />
          </OutsideContent>
        </Route>

        <Route path="/privacy">
          <Helmet>
            <title>Privacy policy - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage>
            <Privacy />
          </OutsideContent>
        </Route>

        <Route path="/cookies">
          <Helmet>
            <title>Cookie policy - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage>
            <Cookies />
          </OutsideContent>
        </Route>

        <Route path="/mission">
          <Helmet>
            <title>Cognys mission - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage>
            <Mission />
          </OutsideContent>
        </Route>

        <Route path="/usecases/:slug">
          <Helmet>
            <title>Use Cases - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage>
            <UseCases />
          </OutsideContent>
        </Route>

        <Route path="/usecases">
          <Helmet>
            <title>Use Cases - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage>
            <UseCases />
          </OutsideContent>
        </Route>

        <Route path="/sst">
          <Helmet>
            <title>Server-side tracking - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage nobg>
            <SST />
          </OutsideContent>
        </Route>

        <Route path="/ga4">
          <Helmet>
            <title>Google Analytics 4 + BigQuery - {defaultTitle}</title>
          </Helmet>

          <OutsideContent subpage>
            <GA4 />
          </OutsideContent>
        </Route>

        <Route exact path="/home">
          <Redirect to="/" />
        </Route>

        <Route exact path="/">
          <Helmet>
            <title>{defaultTitle}</title>
          </Helmet>

          <OutsideContent frontpage>
            <Frontpage />
          </OutsideContent>
        </Route>

        <Route path="*">
          <Helmet>
            <title>Page not found - {defaultTitle}</title>
          </Helmet>

          <OutsideContent>
            <NotFound />
          </OutsideContent>
        </Route>

      </Switch>

      <OutsideFooter />

    </div>
  );
}

export default Outside;
