import './style.scss';

function Warehouses(props) {
  return (
    <div className="Datasources">
      {props.children}
    </div>
  );
}

export default Warehouses;
