import './style.scss';
import avatar from './avatar.svg';

function User(props) {
  const classes = props.super ? props.className + " User--super" : props.className;

  return (
    <div className={"User " + classes }>
      <img src={avatar} alt={props.name} className="User__avatar" />
      <div className="User__text">
        <div className="User__name">{props.name}</div>
        <div className="User__role">{props.role}</div>
      </div>
    </div>
  );
}

User.defaultProps = {
  className: "",
  name: "Missing name",
  role: "Missing role",
};

export default User;