import './style.scss';
import { Link } from 'react-router-dom';
import Icon from 'components/atoms/Icon';

function BackLink(props) {
  return (
    <Link className="BackLink" to={props.to}>
      <Icon icon="arrowLeft" />
      <div className="BackLink__label">Back</div>
    </Link>
  );
}

BackLink.defaultProps = {
  to: '',
};

export default BackLink;
