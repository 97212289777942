import SyntaxHighlighter from 'react-syntax-highlighter';
import './style.scss';
import { useState } from 'react';
import Button from 'components/atoms/Button';
import { agate } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Code(props) {
  const { languange, className, style, children } = props;
  const toggle = props.toggle ?? false;
  const [toggled, setToggled] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();

    setToggled(currentState => !currentState);
  }

  const label = props.label ? props.label : "code";

  return (
    <div className={"Code" + (toggle ? " Code--toggle" : "")}>
      {toggle &&
        <Button className="Code__toggle" onClick={handleToggle}>{toggled ? "Hide " + label : "Show " + label}</Button>
      }
      <div className={"Code__content" + (toggle ? !toggled && " Code__content--hide" : "")}>
        <SyntaxHighlighter className={className} language={languange} style={style ?? agate}>{children}</SyntaxHighlighter>
      </div>
    </div>
  );
}

export default Code;