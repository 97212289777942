import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import { useRouteMatch } from 'react-router-dom';
import LoadingDots from 'components/atoms/LoadingDots';
import { useCookies } from 'react-cookie';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

/*
const TableControlled = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const navigateTo = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  const data = props.data;

  if (!data.rows || !data.columns) return false;

  console.log('data1', data);

  let head = { cells: [] };

  data.columns.forEach((item) => {
    head.cells.push({
      key: item,
      content: item,
      isSortable: false,
      shouldTruncate: false
    })
  });

  let rows = []

  data.rows.forEach(items => {
    let obj = {
      key: items[0],
      cells: []
    }

    items.forEach(item => {
      const newCell = {
        key: item,
        content: item
      }
      obj.cells.push(newCell)
    })

    rows.push(obj);
  })

  return (
    <DynamicTableStateless
      head={head}
      rows={rows}
      rowsPerPage={16}
      page={pageNumber}
      loadingSpinnerSize="large"
      isLoading={false}
      isFixedSize
      //sortKey={data.columns[0]}
      sortOrder="ASC"
      //onSort={() => console.log('onSort')}
      onSetPage={(e) => navigateTo(e)}
    />
  );
}
*/

function TerminalVisualization(props) {
  const [result, setResult] = useState({});
  const [plot, setPlot] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const match = useRouteMatch();
  const [cookies] = useCookies(["token", "project"]);
  const [warehouseId] = useState(match.params.warehouse_id);

  useEffect(() => {
    let mounted = true;
    setIsLoaded(false);
    if (cookies.token && props.exploration) {
      const api = new CognyAPI(cookies.token);
      /* to own component */
      api.getXFormSampleData(warehouseId, props.exploration)
        .then(
          (result) => {
            if (!mounted) return;
            setResult(result);

            if (result.rows) {
              var plot = result.rows.map((r) => {
                var row = {};

                result.columns.map((name, i) => {
                  return row[name] = r[i];
                });

                return row;
              });
              setPlot(plot);
            }

            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    } else if (props.exploration === null) {
      setIsLoaded(true);
      setResult({});
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, props.exploration]);

  const helpText = props.help ? props.help.split("\n")[props.help.split("\n").length - 1] : "";
  const helpTextSplit = helpText.split("TLDR: ");
  const explanation = helpText ? helpTextSplit[helpTextSplit.length - 1] : "This is what it looks like."

  if (!result.columns || !result.rows) return <LoadingDots />;

  return (
    <div className="TerminalVisualization">
      {isLoaded &&
        <>
          <div className="TerminalVisualization__chartContainer">
            <h2 className="TerminalVisualization__title">
              {explanation}
            </h2>

            <div className="TerminalVisualization__chart">
              { /* data dependent  */}
              { /* data related */}
              {result.columns &&
                <ResponsiveContainer width='100%' aspect={2.35 / 1.0}>
                  <LineChart width={600} height={250} data={plot}>
                    <CartesianGrid stroke="#ccc" />
                    <YAxis />
                    <Tooltip contentStyle={{ background: '#333', fontWeight: 'bold' }} />
                    <Legend />
                    {result.columns.map((c, i) => {
                      var colors = [
                        "#CC20A6",
                        "#D63B19",
                        "#FFA800",
                        "#FFD600",
                        "#00BD4C",
                        "#00BDBD",
                        "#2283DC",
                      ];
                      if (i === 0) {
                        return <XAxis dataKey={c} key={c + i} />;
                      } else {
                        return <Line type="montone" dataKey={c} key={c + i} stroke={colors[i - 1]} fill={colors[i - 1]} />;
                      }
                    })}
                  </LineChart>
                </ResponsiveContainer>
              }
            </div>
          </div>

          <div className="TerminalVisualization__tableContainer">
            <h2 className="TerminalVisualization__title">
              And this is some sample data of this exploration.
            </h2>
            <div className="TerminalVisualization__table">
              {/*

              <TableControlled data={result} />

              */
              }
              <div className="datatable-container">
                {
                  result.rows && result.columns &&
                  <table className="datatable">
                    <thead>
                      <tr>
                        {result.columns && result.columns.map((item, index) => {
                          return (
                            <th key={index}>{item}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {result.rows && result.rows.map((row, index) => {
                        return (<tr key={index}>
                          {row.map((item, index) => {
                            return (
                              <td key={index}>{item}</td>
                            )
                          })}
                        </tr>)
                      })}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </>
      }
      {!isLoaded && <LoadingDots />}
      {error ? <div>{error.message}</div> : ""}
    </div>
  )
}

export default TerminalVisualization;
