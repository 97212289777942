import './style.scss';

function Tasks(props) {
  return (
    <div className="Tasks">
      {props.children}
    </div>
  );
}

export default Tasks;
