import './style.scss';
import { useState } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import Header from 'components/molecules/Header';
import Title from 'components/atoms/Title';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import SectionHeader from 'components/molecules/SectionHeader';
import Field from 'components/molecules/Field';

const ContactFormRow = (props) => {
  const className = props.id ? "ContactForm__row--" + props.id : "";
  return <div key={props.id} className={"ContactForm__row " + className}>{props.children}</div>;
}

function ContactForm(props) {
  const [subject, setSubject] = useState(0);
  const [message, setMessage] = useState("");
  const [contactFormIsLoaded, setContactFormIsLoaded] = useState(null);
  const [contactFormError, setContactFormError] = useState(null);
  const [validation, setValidation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidation(true);
    if (!subject || !message) return;

    setContactFormIsLoaded(false);

    const postData = {
      subject,
      message
    };

    const api = new CognyAPI();
    api.sendMessage(postData)
      .then(
        (result) => {
          setContactFormIsLoaded(true);
        },
        (error) => {
          setContactFormIsLoaded(true);
          setContactFormError(error);
        }
      );
  }

  const Validation = (props) => {
    if (validation) {
      if (!subject && props.id === "subject") {
        return <div className="ContactForm__validation ContactForm__validation--invalid">You need to enter a subject</div>;
      } else if (!message && props.id === "message") {
        return <div className="ContactForm__validation ContactForm__validation--invalid">You need to write a message</div>;
      }
    }
    return "";
  };

  const options = [
    { value: 'change-account-info', label: 'I want to change my account info' },
    { value: 'change-plan', label: 'I want to change my monthly plan' },
    { value: 'to-admin', label: 'A message to Cogny admins' },
  ];

  return (
    <>
      <Header></Header>
      <div className="ContactForm">
        <SectionHeader>
          <Title>Contact us</Title>
        </SectionHeader>

        <Form enable onSubmit={handleSubmit}>
          <ContactFormRow id="subject">
            <Field
              className="ContactForm__subjectSelect"
              classNamePrefix="Select"
              type="select"
              options={options}
              id="subject" 
              name="Subject"
              onChange={(e) => setSubject(e.value)}
            />
            <Validation id="subject" />
          </ContactFormRow>

          <ContactFormRow id="message">
            <Field
              type="textarea"
              id="message"
              name="Message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              placeholder="Your message"
            />
            <Validation id="message" />
          </ContactFormRow>

          <ContactFormRow id="submit">
            <Button className="ContactForm__submitButton" type="submit" icon="arrowRight" iconSide="right">Send message</Button>
            <SubmitStatus isLoaded={contactFormIsLoaded} error={contactFormError} parentClass="ContactForm" successMessage="Your message has been sent!" />
          </ContactFormRow>
        </Form>
      </div>
    </>
  );
}

export default ContactForm;