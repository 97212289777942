import './style.scss';
import SubpageHero from 'outside/components/SubpageHero';
import useBodyClass from 'hooks/useBodyClass';
import OutsideBox from 'outside/components/OutsideBox';
import cognyModel from './assets/cognyModelPart2.png';
import cognyModelSmall from './assets/cognyModelPartMobile2.png';
import { ReactComponent as imyLogo } from './assets/imyLogo.svg';
import imyBeslut from './assets/imyBeslut.png';
import { ReactComponent as iconSweden } from './assets/iconSweden.svg';
import { ReactComponent as iconViking } from './assets/iconViking.svg';
import { ReactComponent as iconEncrypted } from './assets/iconEncrypted.svg';
import { ReactComponent as iconFast } from './assets/iconFast.svg';
import Button from 'components/atoms/Button';
import useWindowSize from 'hooks/useWindowSize';

function SST() {
  useBodyClass('outside-wide');
  const size = useWindowSize();

  const features = [
    {
      icon: iconSweden,
      preTitle: "Krav enligt GDPR",
      title: "Ägs och drivs i Sverige",
    },
    {
      icon: iconViking,
      preTitle: "Krav enligt GDPR",
      title: "Vi har våra servrar i Norden",
    },
    {
      icon: iconEncrypted,
      preTitle: "Förmildrande enligt IMY",
      title: "Anonymisera persondata",
    },
    {
      icon: iconFast,
      preTitle: "Inga ursäkter",
      title: "20 minuter implementation",
    },
  ]

  const IconComponent = (props) => {
    const ComponentName = props.icon;
    return <ComponentName />
  };

  const pricing = [
    {
      title: "Small",
      price: "€99",
      features: [
        "900 Cogny credits",
        "Upp till 20 000 events/månad",
        "Nordisk server-side GTM",
        "BigQuery data warehouse inom EU",
        "Google Analytics 4 datalagring",
        "Enkel integration med Looker Studio",
        "AI-baserad Data Copilot",
        "Email-stöd",
      ],
    },
    {
      title: "Medium",
      price: "€299",
      features: [
        "2 990 Cogny credits",
        "Upp till 100 000 events/månad",
        "Nordisk server-side GTM",
        "BigQuery data warehouse inom EU",
        "Google Analytics 4 datalagring",
        "Enkel integration med Looker Studio",
        "AI-baserad Data Copilot",
        "Email-stöd",
      ],
    },
    {
      title: "Large",
      price: "€599",
      features: [
        "6 655 Cogny credits",
        "Upp till 1 000 000 events/månad",
        "Nordisk server-side GTM",
        "BigQuery data warehouse inom EU",
        "Google Analytics 4 datalagring",
        "Enkel integration med Looker Studio",
        "AI-baserad Data Copilot",
        "Email-stöd",
      ],
    },
  ];

  return (
    <div className="SST">
      <SubpageHero title="Server-side tracking i Norden" bigtagline="med GPDR-säkrad data" size="wide" align="center" />

      <div className="SSTHero">
        {size.width < 650 ?
          <img src={cognyModelSmall} alt="" className="SSTHero__image" />
          :
          <img src={cognyModel} alt="" className="SSTHero__image" />
        }
        <div className="SSTHero__text">
          <div>Skyddad persondata stannar inom EU</div>
          <div>Anonymiserad data når USA</div>
        </div>
      </div>

      <div className="SSTFeatures">
        <div className="SSTFeatures__items">
          {features.map((item, index) => {
            return (
              <div className="SSTFeatures__item" key={index}>
                <IconComponent icon={item.icon} />
                <div className="SSTFeatures__pretitle">{item.preTitle}</div>
                <div className="SSTFeatures__title">{item.title}</div>
              </div>
            )
          })}

        </div>
        <div className="SSTFeatures__CTA">
          <Button type="link" href="/login" icon="arrowRight" iconSide="right">Prova Cogny</Button>
        </div>
      </div>

      <OutsideBox classBase="IMY">
        <div className="IMY">
          <h2 className="IMY__title">Europas första stora GDPR-sanktionsavgift utfärdad i Sverige</h2>

          <div className="IMY__text">
            <p>IMY har utfärdat en administrativ sanktionsavgift på 12 miljoner kronor till Tele2 och 300 000 kronor till CDON.</p>

            <p>4 anmäldes men bara 2 fick böter. Dagens industri implementerade en mellanhand eller en europeisk server-side tracking som anonymiserade datan innan den skickas till amerikanska verktyg.</p>
          </div>

          <div className="IMY__box">
            <div className="IMY__quote">
              <IconComponent icon={imyLogo} />
              <div className="IMY__quotetext">“Dagens Industri har genomfört en utförlig kartläggning av livscykeln för personuppgifter som behandlas i Verktyget, och har därvid identifierat och implementerat ett antal ytterligare skyddsåtgärder. Åtgärderna visualiseras översiktligt i bilden.”</div>
              <Button className="IMY__link" type="htmllink" href="https://www.imy.se/globalassets/dokument/beslut/2023/beslut-tillsyn-ga-dagens-industri.pdf" target="_blank" rel="noreferrer">Länk till IMY:s beslut</Button>
            </div>
            <div className="IMY__image">
              <img src={imyBeslut} alt="" />
            </div>
          </div>

          <div className="IMY__text">
            <p>IMYs beslut är en tydlig vägvisare och vi har gått ett steg längre än DI och hostar server side trackingen i Finland samt att Cogny är ett svenkt företag, vilket gör att USA inte kan kräva ut uppgifter från Cogny.</p>
          </div>
        </div>
      </OutsideBox>

      <div className="Pricing">
        <div className="Pricing__header">
          <h2 className="Pricing__title">Priser</h2>
          <div className="Pricing__intro">
            <p>Vi har 3 olika paket beroende på antal event trackade per månad.</p>
            <p>Osäker på hur många events du trackar? <a href="https://support.google.com/analytics/answer/9322688">Läs mer här.</a></p>
          </div>
        </div>

        <div className="Pricing__boxes">
          {pricing.map((item, index) => {
            return (
              <div className="Pricing__box" key={index}>
                <div className="Pricing__head">
                  <h3 className="Pricing__name">{item.title}</h3>

                  <div className="Pricing__info">
                    <div className="Pricing__startsat">Börjar vid</div>
                    <div className="Pricing__price"><span className="Pricing__amount">{item.price}</span> <span className="Pricing__perwhat">/månad</span></div>
                    <div className="Pricing__terms">Faktureras månadsvis beroende på användning</div>
                  </div>
                </div>

                <ul className="Pricing__features">
                  {item.features.map((feature, index) => <li key={index}>{feature}</li>)}
                </ul>

                <Button type="link" href="/login" className="Pricing__picker">Skapa konto</Button>
              </div>
            )
          })}

        </div>
      </div>

    </div>
  );
}

export default SST;