import './style.scss';

function Label(props) {
  return (
    <label className={"Label " + props.className} htmlFor={props.for}>{props.label || props.children}</label>
  );
}

Label.defaultProps = {
  className: "",
};

export default Label;