class CognyAPI {
  constructor(token) {
    if (!token) {
      throw(new Error('Missing API token'));
    }

    this.token = token;
    this.apiHost = process.env.REACT_APP_API_BASE_URL;
    this.url = this.apiHost+"/api/v1";
  }

  getApiHost() {
    return this.apiHost;
  }

  async _call(endpoint, options = {method:"GET"}) {
    const url = this.url;

    let addedOptions = options;
    
    addedOptions.headers = {
      ...addedOptions.headers,
      ...{
        /* These needs updated headers in API to work

        'Content-Type': 'application/json',
        */
        'Authorization': 'Bearer ' + this.token
      }
    }

    return await Promise.race([
      fetch(url + endpoint, addedOptions).then(res => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 401) {
          this._deleteCookie('token');
          throw new Error('Your session has expired');
        }
        throw new Error('Something went wrong');
      }),
      /* inital customer setup takes a really long time so 5 min should do it */
      new Promise((_,reject) => setTimeout(() => reject(new Error('The API connection timed out :(')), 300000))
    ]).catch(e => { throw e })
  }

  _error(message = "") {
    return new Promise((_,reject) => reject(new Error(message)));
  }

  _deleteCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  async getBalance() {
    return await this._call('/credits/balance')
  }

  async addCoins(postData) {
    if (!postData || typeof postData.amount === "undefined") return this._error('There\'s something wrong with the coins form data');

    return await this._call(
      '/credits/balance',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async sendMessage(postData) {
    if (!postData) return this._error('There\'s something wrong with the contact form data');

    return await this._call(
      '/contact',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addWarehouse(project, postData) {
    return await this._call(
      '/warehouses/add',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async getBilling(id) {
    return await this._call('/billing/' + id)
  }

  async getWarehouses() {
    return await this._call('/warehouses')
  }

  async getWarehouse(id) {
    return await this._call('/warehouses/' + id)
  }

  async getWarehouseAccess(id) {
    return await this._call('/warehouses/' + id + "/access")
  }


  async getWarehouseProcessingStats(id) {
    return await this._call('/warehouses/' + id + '/stats/processing')
  }

  async getWarehouseStorageStats(id) {
    return await this._call('/warehouses/' + id + '/stats/storage')
  }

  async updateXForm(warehouse, id, postData) {

    return await this._call(
      '/xforms/' + warehouse + "/" + id,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async getXForms(project) {
    return await this._call('/xforms/' + project)
  }

  async getXFormDetail(project, id) {
    return await this._call('/xforms/' + project + "/" + id)
  }

  async getXFormSampleData(project, id) {
    return await this._call('/xforms/' + project + "/" + id + "/data")
  }

  async addXForm(project, postData) {
    return await this._call(
      '/xforms/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async deleteXForm(project, id) {
    if (!id) return this._error('Missing xform id value');
    return await this._call('/xforms/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }


  async getImpacts(project) {
    return await this._call('/impacts/' + project)
  }

  async getImpact(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/impacts/' + project + '/' + id)
  }

  async getDatasources(project) {
    return await this._call('/datasources/' + project)
  }

  async getDatasourceDetails(project) {
    return await this._call('/datasources/' + project + '/details')
  }

  async deleteDatasource(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/datasources/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }

  async getDatasource(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/datasources/' + project + '/' + id)
  }


  async getTasks(project) {
    return await this._call('/questions/' + project)
  }

  async getTask(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/questions/' + project + '/' + id)
  }

  async addWarehouseFeature(project, postData) {
    return await this._call(
      '/warehouses/' + project + '/feature/add',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addImpactAnalysis(project, postData) {
    return await this._call(
      '/impacts/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async rerunImpactAnalysis(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/impacts/' + project + '/rerun/' + id,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
  }

  async deleteImpactAnalysis(project, id) {
    if (!id) return this._error('Missing task id value');
    return await this._call('/impacts/' + project + '/' + id,
      {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
  }

  async addAccess(project, postData) {
    return await this._call(
      '/warehouses/' + project + '/access/add',
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addDatasource(project, postData) {
    if (!postData || typeof postData.amount !== "undefined") return this._error('There\'s something wrong with the task form data');

    console.log("in add data source");

    return await this._call(
      '/datasources/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async addTask(project, postData) {
    if (!postData || typeof postData.amount !== "undefined") return this._error('There\'s something wrong with the task form data');

    return await this._call(
      '/questions/' + project,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }

  async updateTask(postData) {
    if (!postData || typeof postData.amount !== "undefined") return this._error('There\'s something wrong with the task form data');

    return await this._call(
      '/questions',
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      }
    )
  }
}

export default CognyAPI;
