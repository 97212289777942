import './style.scss';
import React from 'react';
import BackLink from 'components/molecules/BackLink';
import Header from 'components/molecules/Header';
import AddWarehouse from 'components/molecules/AddWarehouse';

function WarehouseAdd() {

  return (
    <>
      <Header>
        <BackLink to="/home" />
      </Header>

      <AddWarehouse />
    </>
  )
}

export default WarehouseAdd;
