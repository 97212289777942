import './style.scss';
import React from 'react';
import StatusPill from '../StatusPill';

function TaskStatus(props) {
  if (props.count === 0) {
    return "";
  }

  return (
    <div className="TaskStatus" id={"TaskStatus__" + props.status} key={props.status}>
      
      <div className="TaskStatus__header">
        <h2 className="TaskStatus__title"><StatusPill status={props.title} /></h2>
      </div>
      <div className="TaskStatus__tasks">
        {props.children}
      </div>
    </div>
  );
}

export default TaskStatus;
