import camelcase from "camelcase";

/**
 * A class for handling all status things
 */
class CognyStatus {
  constructor(status = false) {
    this.status = status;
    this.statuses = [
      'Requested',
      'Estimated',
      'Approved',
      'In progress',
      'Answered',
      'Accepted',
    ];
  }

  /**
   * Check if status is valid
   * @returns {Boolean}
   */
  isValid = () => {
    return this.statuses.indexOf(this.status) !== -1;
  }

  /**
   * Get status label
   * @returns {String} - Status label
   */
  getLabel = () => {
    return this.status;
  }

  /**
   * Get status slug
   * @returns {String} - Status slug
   */
  getSlug = () => {
    return camelcase(this.getLabel());
  }

  /**
   * Get list of all statuses
   * @returns {Array} - List of all statuses
   */
  getStatuses = () => {
    return this.statuses;
  }

  /**
   * Check if status is final
   * @returns {Boolean}
   */
  isFinal = () => {
    if (!this.status) throw(new Error('Missing final status parameter'));

    const finalStatus = this.statuses.pop();
    return finalStatus === this.status;
  }

  /**
   * Returns array of todo statuses
   * @returns {Array} - Labels of todo statuses
   */
   getTodoStatuses = () => {
    return [ this.statuses[1], this.statuses[4] ];
  }

  /**
   * Check if status is in todo
   * @returns {Boolean}
   */
  isTodo = () => {
    if (!this.status) throw(new Error('Missing todo status parameter'));

    const todoStatuses = this.getTodoStatuses();
    return todoStatuses.indexOf(this.status) > -1;
  }

  /**
   * Returns array of ongoing statuses
   * @returns {Array} - Labels of ongoing statuses
   */
  getOngoingStatuses = () => {
    const ongoingStatuses = this.statuses;
    ongoingStatuses.pop();
    return ongoingStatuses;
  }

  /**
   * Check if status is ongoing
   * @returns {Boolean}
   */
  isOngoing = () => {
    if (!this.status) throw(new Error('Missing ongoing status parameter'));

    const ongoingStatuses = this.getOngoingStatuses();
    return ongoingStatuses.indexOf(this.status) > -1;
  }

  /**
   * Returns array of waiting statuses
   * @returns {Array} - Labels of ongoing statuses
   */
  getWaitingStatuses = () => {
    return [ this.statuses[0], this.statuses[2], this.statuses[3] ];
  }

  /**
   * Check if status is waiting
   * @returns {Boolean}
   */
  isWaiting = () => {
    if (!this.status) throw(new Error('Missing waiting status parameter'));

    const waitingStatuses = this.getWaitingStatuses();
    return waitingStatuses.indexOf(this.status) > -1;
  }

  /**
   * Check if a status is at least another status or after
   * @param {String} statusThreshold 
   * @returns {Boolean}
   */
  atLeastStatus = (statusThreshold = "") => {
    const end = this.statuses.indexOf(this.status);
    const start = this.statuses.indexOf(statusThreshold);

    /**
    * Don't throw these errors for now. Just return false if status doesn't exist.

    if (start === -1) {
      throw(new Error('Comparison status \'' + statusThreshold + '\' value is invalid'));
    } else if (end === -1) {
      throw(new Error('Current status \'' + currentStatus + '\' value is invalid'));
    }
    */

    if (start === -1 || end === -1) {
      return false;
    }

    return start <= end;
  }

  /**
   * Return next status in order if you're not at the last
   * @returns {String} - Status label
   */
  nextStatus = () => {
    const currentStatusIndex = this.statuses.indexOf(this.status);
    const nextStatusIndex = currentStatusIndex + 1;
    return nextStatusIndex <= this.statuses.length ? false : this.statuses[nextStatusIndex];
  }
}

export default CognyStatus;