import './style.scss';

function Unit(props) {
  const { unit, unitSide, parentClass } = props;
  if (unit) {
    if (unitSide === "before") {
      return <div className={parentClass + "__hasUnit " + parentClass + "__hasUnit--before"}><span className={parentClass + "__unit"}>{unit}</span>{props.children}</div>;
    } else {
      return <div className={parentClass + "__hasUnit " + parentClass + "__hasUnit--after"}>{props.children}<span className={parentClass + "__unit"}>{unit}</span></div>;
    }
  } else {
    return <div>{props.children}</div>;
  }
}

Unit.defaultProps = {
  className: "",
  parentClass: "Unit",
};

export default Unit;