import './style.scss';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

function Notification(props) {
  if (!props.message) return "";

  return (
    <div className={"Notification " + props.className }>
      <div className="Notification__content">
        <Icon icon="answered" />
        <span className="Notification__text">{props.message}</span>
      </div>
      <Button 
        onClick={props.onClick} 
        className={"Notification__action Notification__action--" + props.id}
        icon="arrowRight"
        iconSide="right"
        label={props.action}
      />
    </div>
  );
}

Notification.defaultProps = {
  className: "",
};

export default Notification;