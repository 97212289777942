import './style.scss';
import CognyStatus from 'components/_classes/CognyStatus';

function StatusPill(props) {
  const status = new CognyStatus(props.status);
  const statusLabel = status.isValid() ? status.getLabel() : "Unknown";
  const statusSlug = status.getSlug();
  
  return (
    <div className={"StatusPill StatusPill--" + statusSlug + " " + props.className }>{ statusLabel }</div>
  );
}

StatusPill.defaultProps = {
  className: "",
};

export default StatusPill;