import './style.scss';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import { useRouteMatch } from 'react-router-dom';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import BackLink from 'components/molecules/BackLink';
import Header from 'components/molecules/Header';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="TaskEditor__submit" icon="plus">Add Impact Analysis</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="TaskEditor" successMessage="" />
    </>
  )
}

function SettingsAddUser(props) {
  const [task, setTask] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [, setValidation] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const [cookies] = useCookies(["token", "project"]);
  const nameValidation = /.+/;
  const [warehouseId] = useState(match.params.warehouse_id);

  /*
  const validateTask = (name, role) => {
    return typeof nameValidation.test(name) && typeof role !== "undefined" && role.length;
  }
  */

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidation(true);

    /*
    if (!validateTask(task.email, task.role)) {
      return;
    }
    */

    setIsLoaded(false);

    var newAnalysis = {
      name: task.name,
      start_date: task.start_date.toISOString().split("T")[0],
      change_date: task.change_date.toISOString().split("T")[0],
      stop_date: task.stop_date.toISOString().split("T")[0],
    };

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.addImpactAnalysis(warehouseId, newAnalysis)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setTask(taskState => ({
              ...taskState,
              ...taskResult
            }));

            history.push("/" + warehouseId + "/impacts");
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  const handleChange = (e) => {
    if (e.target && e.target.id === "TaskEditor__title") {
      setTask(taskState => ({
        ...taskState,
        name: e.target.value
      }));
    } else {
      setTask(taskState => ({
        ...taskState,
        role: e.value,
      }));
    }
  };

  const Validation = (props) => {
    if (props.id === 'name') {
      if (!nameValidation.test(task.name)) {
        return "Must have a name";
      }
    }
    return "";
  };


  return (
    <>
      <Header>
        <BackLink to={"/" + warehouseId + "/impacts"} />
      </Header>

      <div className="TaskEditor">
        <Form className="TaskEditor__form" onSubmit={handleSubmit}>
          <FieldRow>
            <Field
              type="text"
              id="TaskEditor__title"
              className="TaskEditor__title"
              name="Name"
              value={task.name ? task.name : ''}
              onChange={handleChange}
              validation={<Validation id="name" />}
              placeholder="e.g. Spring Campaign 2023"
            />
          </FieldRow>


          <FieldRow>
            <div class="Field">
              <label class="Label Field__label">Change period start</label>
              <DatePicker
                showIcon
                dateFormat="yyyy-MM-dd"
                selected={task.change_date}
                className="dateInput"
                onChange={(date) => {
                  if (!task.start_date) {
                    setTask(taskState => ({
                      ...taskState,
                      start_date: new Date(date.getTime() + 3600000 - 14 * 86400000),
                    }))
                  }
                  if (!task.stop_date) {
                    setTask(taskState => ({
                      ...taskState,
                      stop_date: new Date(date.getTime() + 3600000 + 14 * 86400000),
                    }))
                  }
                  setTask(taskState => ({
                    ...taskState,
                    change_date: new Date(date.getTime() + 3600000),
                  }))
                }}
              />
            </div>
          </FieldRow>

          <FieldRow>
            <div class="Field">
              <label class="Label Field__label">Change period end</label>
              <DatePicker
                showIcon
                dateFormat="yyyy-MM-dd"
                selected={task.stop_date}
                className="dateInput"
                onChange={(date) =>
                  setTask(taskState => ({
                    ...taskState,
                    stop_date: new Date(date.getTime() + 3600000),
                  }))
                }
              />
            </div>
          </FieldRow>
          <FieldRow>
            <div class="Field">
              <label class="Label Field__label">Pre-period start</label>
              <DatePicker
                showIcon
                dateFormat="yyyy-MM-dd"
                selected={task.start_date}
                className="dateInput"
                onChange={(date) =>
                  setTask(taskState => ({
                    ...taskState,
                    start_date: new Date(date.getTime() + 3600000),
                  }))
                }
              />
            </div>
          </FieldRow>

          <SubmitButton isLoaded={isLoaded} error={error} />
        </Form>
        {isLoaded ? "" : <LoadingDots />}
        {error ? <div>{error.message}</div> : ""}
      </div>
    </>
  )
}

export default SettingsAddUser;
