import './style.scss';
import React from 'react';
import { Link } from 'react-router-dom';
//import Button from 'components/atoms/Button';

function AccessListing(props) {
  const { task } = props;

  return (
    <div className="TaskCard" key={task.id}>
      <div className="TaskCard__main">
        <Link className="TaskCard__link"> { /* to={"/" + props.warehouseId + "/datasource/" + task.gid}> */ }
          <h3 className="TaskCard__title">{task.email}</h3>
        </Link>
      </div>
      <div className="TaskCard__meta">
        <h4 className="TaskCard__title">Role: {task.role}</h4>
      </div>
    </div>
  );
}

//<Button type="link" href={"/warehouse/" + props.warehouseId + "/access/edit/" + task.id} className="TaskList__addTask" icon="user">Edit access</Button>

export default AccessListing;
