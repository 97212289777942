import 'App.scss';
import { useEffect } from 'react';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import Main from 'components/templates/Main';

import ReactGA from "react-ga4";

//import Home from 'pages/Home';
import Tasks from 'pages/Tasks/index';

import Home from 'pages/Home/index';
import HomeView from 'components/organisms/HomeView';

import Dashboard from 'pages/Dashboard/index';
import DashboardMain from 'components/organisms/DashboardMain';

import DataTerminal from 'pages/DataTerminal/index';
import Terminal from 'components/organisms/Terminal';

import Datasources from 'pages/Datasources/index';
import DatasourceGuide from 'components/organisms/DatasourceGuide';
import DatasourceDetails from 'components/organisms/DatasourceDetails';

import Settings from 'pages/Settings/index';
import SettingsPage from 'components/organisms/SettingsPage';
import SettingsAddUser from 'components/organisms/SettingsAddUser';

import Warehouses from 'pages/Warehouses/index';
import WarehouseAdd from 'components/organisms/WarehouseAdd';

import MMM from 'pages/MMM/index';
import MMMForm from 'components/organisms/MMMForm';

import DataVis from 'pages/DataVis/index';
import DataVisForm from 'components/organisms/DataVisForm';
import DataVisPlaybook from 'components/organisms/DataVisPlaybook';

import LTV from 'pages/LTV/index';
import LTVForm from 'components/organisms/LTVForm';

import Persona from 'pages/Persona/index';
import PersonaForm from 'components/organisms/PersonaForm';

import ImpactAnalysisPage from 'pages/ImpactAnalysis/index';
import ImpactAnalysisList from 'components/organisms/ImpactAnalysisList';
import ImpactAnalysisDetail from 'components/organisms/ImpactAnalysisDetail';
import ImpactAnalysisAdd from 'components/organisms/ImpactAnalysisAdd';

import DataExploration from 'pages/DataExploration/index';
import DataExplorationForm from 'components/organisms/DataExplorationForm';

import Audiences from 'pages/Audiences/index';
import AudiencesForm from 'components/organisms/AudiencesForm';

import Account from 'pages/Account';
import AccountForm from 'components/organisms/AccountForm';
import Coins from 'pages/Coins';
import Contact from 'pages/Contact';
import TaskDetails from 'components/organisms/TaskDetails';
import TaskEditor from 'components/organisms/TaskEditor';
import TaskList from 'components/organisms/TaskList';

import CurrentCoins from 'components/organisms/CurrentCoins';
import AddCoins from 'components/organisms/AddCoins';

import Terms from 'outside/pages/Terms';
import Privacy from 'outside/pages/Privacy';
import TerminalSidebar from 'components/molecules/TerminalSidebar';
import DatasourceSidebar from 'components/molecules/DatasourceSidebar';

function App() {
  const [cookies, setCookie] = useCookies(["token", "project"]);
  const { search } = useLocation();
  const useQuery = new URLSearchParams(search);

  // done by GTM?
  //ReactGA.initialize("G-XT72KY3FLP");

  useEffect(() => {
    if (useQuery.get("auth") !== null) {
      const token = useQuery.get("auth");

      setCookie("token", token, { path: "/", sameSite: "strict" });

      if (useQuery.get("f") !== null) {
        const first = useQuery.get("f");
        if (first === "true") {
          ReactGA.event({
            category: "UserActions",
            action: "FirstLogin",
            label: token,
          });
        } else {
          ReactGA.event({
            category: "UserActions",
            action: "Login",
            label: token,
          });
        }
      }
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  const coinsData = {
    coins: false,
    coinsError: null,
    coinsIsLoaded: false
  };

  const getTitle = (name) => {
    return name + " - Cogny";
  }

  return (
    <div className="App">
      <Helmet>
        <title>Cogny</title>
      </Helmet>

      <Switch>

        <Route path="/task/new">
          <Helmet>
            <title>{getTitle("New tasks")}</title>
          </Helmet>

          <Main>
            <Tasks>
              <TaskEditor />
            </Tasks>
          </Main>
        </Route>

        <Route path="/task/:taskId/:tabId">
          <Helmet>
            <title>{getTitle("Task details")}</title>
          </Helmet>

          <Main>
            <Tasks>
              <TaskDetails />
            </Tasks>
          </Main>
        </Route>

        <Route path="/task/:taskId">
          <Helmet>
            <title>{getTitle("Task details")}</title>
          </Helmet>

          <Main>
            <Tasks>
              <TaskDetails />
            </Tasks>
          </Main>
        </Route>

        <Route path="/tasks/:tabId">
          <Helmet>
            <title>{getTitle("My tasks")}</title>
          </Helmet>

          <Main>
            <Tasks>
              <TaskList />
            </Tasks>
          </Main>
        </Route>

        <Route path="/tasks">
          <Helmet>
            <title>{getTitle("My tasks")}</title>
          </Helmet>

          <Main>
            <Tasks>
              <TaskList />
            </Tasks>
          </Main>
        </Route>

        <Route path="/home">
          <Helmet>
            <title>{getTitle("Home")}</title>
          </Helmet>

          <Main noSidebar>
            <Home>
              <HomeView />
            </Home>
          </Main>
        </Route>

        <Route path="/:warehouse_id/dashboard">
          <Helmet>
            <title>{getTitle("Dashboard")}</title>
          </Helmet>

          <Main>
            <Dashboard>
              <DashboardMain />
            </Dashboard>
          </Main>
        </Route>

        <Route path="/:warehouse_id/datasources">
          <Helmet>
            <title>{getTitle("Data sources")}</title>
          </Helmet>

          <Main pageSidebar={<DatasourceSidebar />}>
            <Datasources>
              <DatasourceGuide />
            </Datasources>
          </Main>
        </Route>

        <Route path="/:warehouse_id/datasource/new">
          <Helmet>
            <title>{getTitle("Add Data source")}</title>
          </Helmet>

          <Main pageSidebar={<DatasourceSidebar />}>
            <Datasources>
              <DatasourceGuide />
            </Datasources>
          </Main>
        </Route>

        <Route path="/:warehouse_id/datasource/:taskId">
          <Helmet>
            <title>{getTitle("Data source details")}</title>
          </Helmet>

          <Main pageSidebar={<DatasourceSidebar />}>
            <Datasources>
              <DatasourceDetails />
            </Datasources>
          </Main>
        </Route>

        <Route path="/:warehouse_id/terminal/:id">
          <Helmet>
            <title>{getTitle("Data terminal")}</title>
          </Helmet>

          <Main pageSidebar={<TerminalSidebar />}>
            <DataTerminal>
              <Terminal />
            </DataTerminal>
          </Main>
        </Route>

        <Route path="/:warehouse_id/terminal">
          <Helmet>
            <title>{getTitle("Data terminal")}</title>
          </Helmet>

          <Main pageSidebar={<TerminalSidebar />}>
            <DataTerminal>
              <Terminal />
            </DataTerminal>
          </Main>
        </Route>

        <Route path="/:warehouse_id/datavis/start">
          <Helmet>
            <title>{getTitle("Data visualization")}</title>
          </Helmet>

          <Main>
            <DataVis>
              <DataVisForm />
            </DataVis>
          </Main>
        </Route>

        <Route path="/:warehouse_id/datavis/playbook">
          <Helmet>
            <title>{getTitle("Data visualization playbook")}</title>
          </Helmet>

          <Main>
            <DataVis>
              <DataVisPlaybook />
            </DataVis>
          </Main>
        </Route>

        <Route path="/:warehouse_id/mmm">
          <Helmet>
            <title>{getTitle("Media mix modelling")}</title>
          </Helmet>

          <MMM>
            <MMMForm />
          </MMM>
        </Route>

        <Route path="/:warehouse_id/ltv">
          <Helmet>
            <title>{getTitle("Attribution & LTV")}</title>
          </Helmet>

          <Main>
            <LTV>
              <LTVForm />
            </LTV>
          </Main>
        </Route>

        <Route path="/:warehouse_id/impacts">
          <Helmet>
            <title>{getTitle("Impact Analysis")}</title>
          </Helmet>

          <Main>
            <ImpactAnalysisPage>
              <ImpactAnalysisList />
            </ImpactAnalysisPage>
          </Main>
        </Route>

        <Route path="/:warehouse_id/impact/new">
          <Helmet>
            <title>{getTitle("Impact Analysis Add")}</title>
          </Helmet>

          <Main>
            <ImpactAnalysisPage>
              <ImpactAnalysisAdd />
            </ImpactAnalysisPage>
          </Main>
        </Route>

        <Route path="/:warehouse_id/impact/:id">
          <Helmet>
            <title>{getTitle("Impact Analysis")}</title>
          </Helmet>

          <Main>
            <ImpactAnalysisPage>
              <ImpactAnalysisDetail />
            </ImpactAnalysisPage>
          </Main>
        </Route>

        <Route path="/:warehouse_id/personas">
          <Helmet>
            <title>{getTitle("Persona modelling")}</title>
          </Helmet>

          <Main>
            <Persona>
              <PersonaForm />
            </Persona>
          </Main>
        </Route>

        <Route path="/:warehouse_id/data_exploration">
          <Helmet>
            <title>{getTitle("Data Exploration")}</title>
          </Helmet>

          <Main>
            <DataExploration>
              <DataExplorationForm />
            </DataExploration>
          </Main>
        </Route>

        <Route path="/:warehouse_id/audiences">
          <Helmet>
            <title>{getTitle("Audiences")}</title>
          </Helmet>

          <Main>
            <Audiences>
              <AudiencesForm />
            </Audiences>
          </Main>
        </Route>

        <Route path="/:warehouse_id/account">
          <Helmet>
            <title>{getTitle("Account")}</title>
          </Helmet>

          <Main>
            <Account>
              <AccountForm />
            </Account>
          </Main>
        </Route>

        <Route path="/:warehouse_id/settings">
          <Helmet>
            <title>{getTitle("Settings")}</title>
          </Helmet>

          <Main>
            <Settings>
              <SettingsPage />
            </Settings>
          </Main>
        </Route>

        <Route path="/:warehouse_id/access/new">
          <Helmet>
            <title>{getTitle("Add User")}</title>
          </Helmet>

          <Main>
            <Settings>
              <SettingsAddUser />
            </Settings>
          </Main>
        </Route>

        <Route path="/warehouse/new">
          <Helmet>
            <title>{getTitle("Setup new data warehouse")}</title>
          </Helmet>

          <Main noSidebar>
            <Warehouses>
              <WarehouseAdd />
            </Warehouses>
          </Main>
        </Route>

        <Route path="/:warehouse_id/coins/add">
          <Helmet>
            <title>{getTitle("Add more coins")}</title>
          </Helmet>

          <Main>
            <Coins>
              <AddCoins />
            </Coins>
          </Main>
        </Route>

        <Route path="/:warehouse_id/coins">
          <Helmet>
            <title>{getTitle("Coin balance")}</title>
          </Helmet>

          <Main>
            <Coins>
              <CurrentCoins coinsData={coinsData} />
            </Coins>
          </Main>
        </Route>

        <Route path="/contact">
          <Helmet>
            <title>{getTitle("Contact")}</title>
          </Helmet>

          <Main>
            <Contact />
          </Main>
        </Route>

        <Route path="/terms">
          <Helmet>
            <title>{getTitle("Terms")}</title>
          </Helmet>

          <Main>
            <Terms />
          </Main>
        </Route>

        <Route path="/privacy">
          <Helmet>
            <title>{getTitle("Privacy")}</title>
          </Helmet>

          <Main>
            <Privacy />
          </Main>
        </Route>

        <Route path="/">
          {/*
            Redirect to /datasources until we've got a real dashboard
            <Home />
          */}
          <Redirect to="/home"></Redirect>
        </Route>

      </Switch>
    </div>
  );
}

export default App;
